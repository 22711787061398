export const S = {
  "Total Sales Processed": [{ "count(o.id)": 1847 }],
  "Total Sales": [{ "Gross Sales": 102348.53575 }],
  "Net Sales": [{ "Net Sales": 95486.3 }],
  "Tips - US": [{ Tips: 298.82 }],
  "Tax - US": [{ Tax: 6327.77575 }],
  "Day of the Week": [
    { "Average Sales in Dollars": 29.3, day: "Sunday" },
    { "Average Sales in Dollars": 30.3, day: "Monday" },
    { "Average Sales in Dollars": 32.75, day: "Tuesday" },
    { "Average Sales in Dollars": 58.9, day: "Wednesday" },
    { "Average Sales in Dollars": 47.6, day: "Thursday" },
    { "Average Sales in Dollars": 39.2, day: "Friday" },
    { "Average Sales in Dollars": 52.45, day: "Saturday" },
  ],
  "Hour of the Day": [
    { "Average Sales per Hour": 250, Hour: "09:00" },
    { "Average Sales per Hour": 275, Hour: "10:00" },
    { "Average Sales per Hour": 320, Hour: "11:00" },
    { "Average Sales per Hour": 300, Hour: "12:00" },
    { "Average Sales per Hour": 280, Hour: "13:00" },
    { "Average Sales per Hour": 290, Hour: "14:00" },
    { "Average Sales per Hour": 310, Hour: "15:00" },
    { "Average Sales per Hour": 330, Hour: "16:00" },
    { "Average Sales per Hour": 340, Hour: "17:00" },
    { "Average Sales per Hour": 350, Hour: "18:00" },
  ],
  "Cancel Item Tracker": [
    {
      "Order no": "002256",
      Steward: "Mohan Gowda",
      "Order type": "Pickup",
      "Item name": "Kadai Vegatables",
      "Refunded Quantity": 1,
      Amount: 14.99,
      Reason: null,
      Time: "2024-06-23T18:51:47-04:00",
    },
    {
      "Order no": "002256",
      Steward: "Mohan Gowda",
      "Order type": "Pickup",
      "Item name": "Roti",
      "Refunded Quantity": 1,
      Amount: 3.99,
      Reason: "Others",
      Time: "2024-06-23T18:51:47-04:00",
    },
    {
      "Order no": "002143",
      Steward: "Mohan Gowda",
      "Order type": "Pickup",
      "Item name": "Royal Falooda",
      "Refunded Quantity": 1,
      Amount: 13.49,
      Reason: "Missing Item",
      Time: "2024-05-12T21:47:16-04:00",
    },
    {
      "Order no": "",
      Steward: "Mohan Gowda",
      "Order type": "Delivery",
      "Item name": "South Indian Thali",
      "Refunded Quantity": 1,
      Amount: 23.0,
      Reason: "Restaurant Closing Time",
      Time: "2024-05-27T22:04:43-04:00",
    },
    {
      "Order no": "",
      Steward: "Mohan Gowda",
      "Order type": "Delivery",
      "Item name": "Paneer Tikka",
      "Refunded Quantity": 1,
      Amount: 19.5,
      Reason: "Restaurant Closing Time",
      Time: "2024-05-27T22:04:43-04:00",
    },
    {
      "Order no": "",
      Steward: "Mohan Gowda",
      "Order type": "Delivery",
      "Item name": "Mini Tiffin",
      "Refunded Quantity": 1,
      Amount: 19.0,
      Reason: "Items not available",
      Time: "2024-06-12T19:04:58-04:00",
    },
    {
      "Order no": "",
      Steward: "Mohan Gowda",
      "Order type": "Delivery",
      "Item name": "South Indian Rush Lunch(Lunch Only)",
      "Refunded Quantity": 1,
      Amount: 19.5,
      Reason: "Items not available",
      Time: "2024-06-12T19:04:58-04:00",
    },
  ],
  "Category - US": [
    { Category: "Dosai Corner", "Items sold": 821, "Total Sales": 12184.29 },
    { Category: "Rice & Noodles", "Items sold": 588, "Total Sales": 8674.62 },
    {
      Category: "South Indian Favorites",
      "Items sold": 367,
      "Total Sales": 4502.83,
    },
    { Category: "Thali's", "Items sold": 437, "Total Sales": 8303.0 },
    { Category: "Appetizers", "Items sold": 992, "Total Sales": 11809.08 },
    {
      Category: "From The House Of Steam",
      "Items sold": 664,
      "Total Sales": 7060.86,
    },
    { Category: "A2B Combos", "Items sold": 338, "Total Sales": 4373.12 },
    { Category: "Impossible", "Items sold": 8, "Total Sales": 121.92 },
    { Category: "Accompaniments", "Items sold": 580, "Total Sales": 2508.96 },
    { Category: "House Specials", "Items sold": 269, "Total Sales": 4135.81 },
    {
      Category: "North Indian Curries",
      "Items sold": 428,
      "Total Sales": 6498.22,
    },
    { Category: "Desserts", "Items sold": 139, "Total Sales": 1145.11 },
    { Category: "Chaats", "Items sold": 115, "Total Sales": 1158.85 },
    {
      Category: "South Indian Curries",
      "Items sold": 133,
      "Total Sales": 2022.17,
    },
    { Category: "Soups", "Items sold": 119, "Total Sales": 950.81 },
    // { Category: "Indo - Chinese", "Items sold": 43, "Total Sales": 604.57 },
    // { Category: "Beverages", "Items sold": 18, "Total Sales": 116.82 },
    // { Category: "Beverages", "Items sold": 2, "Total Sales": 13.48 },
    // { Category: "House Specials", "Items sold": 1, "Total Sales": 15.99 },
    // { Category: "Appetizers", "Items sold": 4, "Total Sales": 44.46 },
    // {
    //   Category: "From The House Of Steam",
    //   "Items sold": 1,
    //   "Total Sales": 13.99,
    // },
    // {
    //   Category: "South Indian Favorites",
    //   "Items sold": 1,
    //   "Total Sales": 14.49,
    // },
    // { Category: "Extras", "Items sold": 1, "Total Sales": 2.5 },
  ],
  "Revenue Class": [
    { "Revenue Class": "FOOD", "Items sold": 4845, "Total Sales": 65387.39 },
    { "Revenue Class": "GRIDDLE", "Items sold": 2455, "Total Sales": 27910.08 },
    { "Revenue Class": "PANTRY", "Items sold": 403, "Total Sales": 3659.57 },
  ],
  // Discount Summary data is Dev Data
  "Discount Summary": [
    {
      "#Discount amount": "33.1700",
      "Order no": "001119",
      "Table name": "T1",
    },
    {
      "#Discount amount": "36.7700",
      "Order no": "001109",
      "Table name": "T3",
    },
    {
      "#Discount amount": "33.5700",
      "Order no": "001089",
      "Table name": "T4",
    },
    {
      "#Discount amount": "62.2400",
      "Order no": "001050",
      "Table name": "T2",
    },
    {
      "#Discount amount": "21.0800",
      "Order no": "001049",
      "Table name": "T1",
    },
    {
      "#Discount amount": "0.7700",
      "Order no": "001008",
      "Table name": "T2",
    },
    {
      "#Discount amount": "2.1700",
      "Order no": "000888",
      "Table name": "T1",
    },
  ],
  // Discount Summary data is Dev Data
  "Payment Mode": [
    {
      "Payment Mode": "UberEats",
      "#Total Orders": 941,
      "#Amount Paid": 40443.212865,
    },
    {
      "Payment Mode": "GrubHub",
      "#Total Orders": 447,
      "#Amount Paid": 20772.931516,
    },
    {
      "Payment Mode": "doordash_pos",
      "#Total Orders": 277,
      "#Amount Paid": 10441.881369,
    },
    {
      "Payment Mode": "Online/Key-In",
      "#Total Orders": 179,
      "#Amount Paid": 9983.85,
    },
    { "Payment Mode": "CASH", "#Total Orders": 3, "#Amount Paid": 141.53 },
  ],
  // Direct Store/Online Sales (Maghil) MOCK
  "Direct Store/Online Sales": [
    { "Payment Mode": "CASH", "#TotalSales": 14324.5500, "#Total Orders": 115 },
    { "Payment Mode": "CASH", "#TotalSales": 14324.5, "#Total Orders": 112 },
    { "Payment Mode": "CASH", "#TotalSales": 14324.550, "#Total Orders": 113 }
  ],
  "Actual 3rd Party Sales":
    [
      { "Payment Mode": "Zomato", "#Total Orders": 2, "#TotalSales": 200.0000 },
      { "Payment Mode": "Grubhub", "#Total Orders": 1, "#TotalSales": 22.7300 },
      { "Payment Mode": "CASH", "#Total Orders": 1, "#TotalSales": 21.0000 }
    ]

};
