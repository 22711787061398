// Sales Summary :
export const SALES_SUMMARY_REQUEST = 'SALES_SUMMARY_REQUEST';
export const SALES_SUMMARY_SUCCESS = 'SALES_SUMMARY_SUCCESS';
export const SALES_SUMMARY_FAILURE = 'SALES_SUMMARY_FAILURE';

// sales by item category :
export const SALES_BY_ITEM_CATEGORY_REQUEST = 'SALES_BY_ITEM_CATEGORY_REQUEST';
export const SALES_BY_ITEM_CATEGORY_SUCCESS = 'SALES_BY_ITEM_CATEGORY_SUCCESS';
export const SALES_BY_ITEM_CATEGORY_FAILURE = 'SALES_BY_ITEM_CATEGORY_FAILURE';

//sales by revenue class :
export const SALES_BY_REVENUE_CLASS_REQUEST = 'SALES_BY_REVENUE_CLASS_REQUEST';
export const SALES_BY_REVENUE_CLASS_SUCCESS = 'SALES_BY_REVENUE_CLASS_SUCCESS';
export const SALES_BY_REVENUE_CLASS_FAILURE = 'SALES_BY_REVENUE_CLASS_FAILURE';

// actual sales Maghil :
export const ACTUAL_SALES_REQUEST = 'ACTUAL_SALES_REQUEST';
export const ACTUAL_SALES_SUCCESS = 'ACTUAL_SALES_SUCCESS';
export const ACTUAL_SALES_FAILURE = 'ACTUAL_SALES_FAILURE';

// actual sales third party :
export const ACTUAL_SALES_THIRD_PARTY_REQUEST = 'ACTUAL_SALES_THIRD_PARTY_REQUEST';
export const ACTUAL_SALES_THIRD_PARTY_SUCCESS = 'ACTUAL_SALES_THIRD_PARTY_SUCCESS';
export const ACTUAL_SALES_THIRD_PARTY_FAILURE = 'ACTUAL_SALES_THIRD_PARTY_FAILURE';

//Hourly sales :
export const HOURLY_SALES_REQUEST = 'HOURLY_SALES_REQUEST';
export const HOURLY_SALES_SUCCESS = 'HOURLY_SALES_SUCCESS';
export const HOURLY_SALES_FAILURE = 'HOURLY_SALES_FAILURE';

// Live discount :
export const LIVE_DISCOUNT_REQUEST = 'LIVE_DISCOUNT_REQUEST';
export const LIVE_DISOUNT_SUCCESS = 'LIVE_DISOUNT_SUCCESS';
export const LIVE_DISCOUNT_FAILURE = 'LIVE_DISCOUNT_FAILURE';

// Open Sales :
export const LIVE_OPEN_SALES_REQUEST = 'LIVE_OPEN_SALES_REQUEST';
export const LIVE_OPEN_SALES_SUCCESS = 'LIVE_OPEN_SALES_SUCCESS';
export const LIVE_OPEN_SALES_FAILURE = 'LIVE_OPEN_SALES_FAILURE';

// live orders :
export const LIVE_ORDERS_REQUEST = 'LIVE_ORDERS_REQUEST';
export const LIVE_ORDERS_SUCCESS = 'LIVE_ORDERS_SUCCESS';
export const LIVE_ORDERS_FAILURE = 'LIVE_ORDERS_FAILURE';

// live refunds :
export const LIVE_REFUNDS_REQUEST = 'LIVE_REFUNDS_REQUEST';
export const LIVE_REFUNDS_SUCCESS = 'LIVE_REFUNDS_SUCCESS';
export const LIVE_REFUNDS_FAILURE = 'LIVE_REFUNDS_FAILURE';

//  net sales :
export const LIVE_NET_SALES_REQUEST = 'LIVE_NET_SALES_REQUEST'
export const LIVE_NET_SALES_SUCCESS = 'LIVE_NET_SALES_SUCCESS'
export const LIVE_NET_SALES_FAILURE = 'LIVE_NET_SALES_FAILURE'

// live order non dine in :
export const LIVE_ORDER_NON_DINE_IN_REQUEST = 'LIVE_ORDER_NON_DINE_IN_REQUEST'
export const LIVE_ORDER_NON_DINE_IN_SUCCESS = 'LIVE_ORDER_NON_DINE_IN_SUCCESS'
export const LIVE_ORDER_NON_DINE_IN_FAILURE = 'LIVE_ORDER_NON_DINE_IN_FAILURE'

// discuount summary :
export const DISCOUNT_SUMMARY_REQUEST = 'DISCOUNT_SUMMARY_REQUEST';
export const DISCOUNT_SUMMARY_SUCCESS = 'DISCOUNT_SUMMARY_SUCCESS';
export const DISCOUNT_SUMMARY_FAILURE = 'DISCOUNT_SUMMARY_FAILURE';

// cancellation summary :
export const CANCELLATION_SUMMARY_REQUEST = 'CANCELLATION_SUMMARY_REQUEST'
export const CANCELLATION_SUMMARY_SUCCESS = 'CANCELLATION_SUMMARY_SUCCESS'
export const CANCELLATION_SUMMARY_FAILURE = 'CANCELLATION_SUMMARY_FAILURE'

// employee staff tip gratuity :
export const EMPLOYEE_STAFF_TIP_GRATUITY_REQUEST = 'EMPLOYEE_STAFF_TIP_GRATUITY_REQUEST'
export const EMPLOYEE_STAFF_TIP_GRATUITY_SUCCESS = 'EMPLOYEE_STAFF_TIP_GRATUITY_SUCCESS'
export const EMPLOYEE_STAFF_TIP_GRATUITY_FAILURE = 'EMPLOYEE_STAFF_TIP_GRATUITY_FAILURE'

// employee staff discount :
export const EMPLOYEE_STAFF_DISCOUNT_REQUEST = 'EMPLOYEE_STAFF_DISCOUNT_REQUEST'
export const EMPLOYEE_STAFF_DISCOUNT_SUCCESS = 'EMPLOYEE_STAFF_DISCOUNT_SUCCESS'
export const EMPLOYEE_STAFF_DISCOUNT_FAILURE = 'EMPLOYEE_STAFF_DISCOUNT_FAILURE'

// employee staff performance :
export const EMPLOYEE_STAFF_PERFORMANCE_REQUEST = 'EMPLOYEE_STAFF_PERFORMANCE_REQUEST'
export const EMPLOYEE_STAFF_PERFORMANCE_SUCCESS = 'EMPLOYEE_STAFF_PERFORMANCE_SUCCESS'
export const EMPLOYEE_STAFF_PERFORMANCE_FAILURE = 'EMPLOYEE_STAFF_PERFORMANCE_FAILURE'

// employee staff activity :
export const EMPLOYEE_STAFF_ACTIVITY_REQUEST = 'EMPLOYEE_STAFF_ACTIVITY_REQUEST'
export const EMPLOYEE_STAFF_ACTIVITY_SUCCESS = 'EMPLOYEE_STAFF_ACTIVITY_SUCCESS'
export const EMPLOYEE_STAFF_ACTIVITY_FAILURE = 'EMPLOYEE_STAFF_ACTIVITY_FAILURE'