export const ProdI = {
  "Top 20 Popular Items": [
    { "Product name": "South Indian Thali", Quantity: 437 },
    { "Product name": "Idli", Quantity: 407 },
    { "Product name": "Masala Dosai", Quantity: 165 },
    { "Product name": "Mini Tiffin Combo", Quantity: 139 },
    { "Product name": "Vegetable Dum Biriyani", Quantity: 132 },
    { "Product name": "Medhu Vadai", Quantity: 126 },
    { "Product name": "Idli Vadai Combo", Quantity: 122 },
    { "Product name": "Poori Masala", Quantity: 121 },
    { "Product name": "Sambar Thatte Idly", Quantity: 106 },
    { "Product name": "Mysore Masala Dosai", Quantity: 104 },
    { "Product name": "Cocktail Sambar Idli", Quantity: 88 },
    { "Product name": "Cauliflower 65", Quantity: 86 },
    { "Product name": "Chole Bhature", Quantity: 86 },
    { "Product name": "Parotta Kurma", Quantity: 85 },
    { "Product name": "Ghee Pongal", Quantity: 84 },
    { "Product name": "Curd Rice", Quantity: 74 },
    { "Product name": "Masala Uthappam", Quantity: 72 },
    { "Product name": "Chilli Bajji", Quantity: 70 },
    { "Product name": "Pineapple Rava Kesari", Quantity: 63 },
    { "Product name": "Sambar Vadai", Quantity: 63 },
  ],
  "Top 20 Revenue Making Products": [
    { "Product name": "South Indian Thali", total_product_sales: 7407.84 },
    { "Product name": "Idli", total_product_sales: 2785.205 },
    { "Product name": "Mini Tiffin Combo", total_product_sales: 1888.6 },
    { "Product name": "Masala Dosai", total_product_sales: 1723.74 },
    { "Product name": "Vegetable Dum Biriyani", total_product_sales: 1575.405 },
    { "Product name": "Sambar Thatte Idly", total_product_sales: 1232.2 },
    { "Product name": "Mysore Masala Dosai", total_product_sales: 1180.86 },
    { "Product name": "Poori Masala", total_product_sales: 1034.64 },
    { "Product name": "Chole Bhature", total_product_sales: 999.57 },
    { "Product name": "Parotta Kurma", total_product_sales: 920.325 },
    { "Product name": "Masala Uthappam", total_product_sales: 898.42 },
    { "Product name": "Medhu Vadai", total_product_sales: 834.195 },
    { "Product name": "Vegetable Fried Rice", total_product_sales: 755.43 },
    { "Product name": "Ghee Pongal", total_product_sales: 753.065 },
    { "Product name": "Chilli Bajji", total_product_sales: 738.93 },
    { "Product name": "Cocktail Sambar Idli", total_product_sales: 729.33 },
    { "Product name": "Idli Vadai Combo", total_product_sales: 719.9 },
    { "Product name": "Onion Rava Dosai", total_product_sales: 681.4 },
    { "Product name": "Sambar Vadai", total_product_sales: 675.24 },
    { "Product name": "Cauliflower 65", total_product_sales: 670.16 },
  ],
  // "Least 20 Popular Items": [
  //   { "Product name": "Aloo Kulcha", Quantity: 1 },
  //   { "Product name": "Baingan Ka Bharta", Quantity: 1 },
  //   { "Product name": "Dal Makhani", Quantity: 1 },
  //   { "Product name": "Dal Palak", Quantity: 1 },
  //   { "Product name": "Ennai Kathirikai Kuzhambu", Quantity: 1 },
  //   { "Product name": "Hot & Sour Soup", Quantity: 1 },
  //   { "Product name": "Hot & Sour Veg Soup", Quantity: 1 },
  //   { "Product name": "Impossible Vegetable Laba", Quantity: 1 },
  //   { "Product name": "Kadai Mushroom", Quantity: 1 },
  //   { "Product name": "Karuveppilai Poondu Kuzhambu", Quantity: 1 },
  //   { "Product name": "Madras Coffee", Quantity: 1 },
  //   { "Product name": "Malai Kofta", Quantity: 1 },
  //   { "Product name": "Masala", Quantity: 1 },
  //   { "Product name": "Mixed Veg Pakoda", Quantity: 1 },
  //   { "Product name": "Mushroom Chettinad", Quantity: 1 },
  //   { "Product name": "Musroom Pulao", Quantity: 1 },
  //   { "Product name": "Mysore Rava  Masala Dosai", Quantity: 1 },
  //   { "Product name": "Navratan Korma", Quantity: 1 },
  //   { "Product name": "Onion Masala Dosai", Quantity: 1 },
  //   { "Product name": "Palak Paneer", Quantity: 1 },
  // ],
  "Least 20 Popular Items": [
    { "Product name": "Aloo Kulcha", Quantity: 1 },
    { "Product name": "Baingan Ka Bharta", Quantity: 2 },
    { "Product name": "Dal Makhani", Quantity: 2 },
    { "Product name": "Dal Palak", Quantity: 3 },
    { "Product name": "Ennai Kathirikai Kuzhambu", Quantity: 4 },
    { "Product name": "Hot & Sour Soup", Quantity: 5 },
    { "Product name": "Hot & Sour Veg Soup", Quantity: 6 },
    { "Product name": "Impossible Vegetable Laba", Quantity: 6 },
    { "Product name": "Kadai Mushroom", Quantity: 8 },
    { "Product name": "Karuveppilai Poondu Kuzhambu", Quantity: 9 },
    { "Product name": "Madras Coffee", Quantity: 10 },
    { "Product name": "Malai Kofta", Quantity: 12 },
    { "Product name": "Masala", Quantity: 15 },
    { "Product name": "Mixed Veg Pakoda", Quantity: 15 },
    { "Product name": "Mushroom Chettinad", Quantity: 18 },
    { "Product name": "Musroom Pulao", Quantity: 20 },
    { "Product name": "Mysore Rava Masala Dosai", Quantity: 25 },
    { "Product name": "Navratan Korma", Quantity: 30 },
    { "Product name": "Onion Masala Dosai", Quantity: 35 },
    { "Product name": "Palak Paneer", Quantity: 40 },
  ],
  "Least 20 Revenue making Products": [
    { "Product name": "Aloo Kulcha", total_product_sales: 0.06 },
    { "Product name": "Ragi(Finger) Millet Dosai", total_product_sales: 0.175 },
    { "Product name": "Onion Masala Dosai", total_product_sales: 0.19 },
    { "Product name": "Paneer Kulcha", total_product_sales: 0.24 },
    { "Product name": "Paneer Paratha", total_product_sales: 0.28 },
    { "Product name": "Adai Aviyal", total_product_sales: 0.72 },
    { "Product name": "Idiyappam", total_product_sales: 1.7 },
    { "Product name": "Set Dosai Vadacurry", total_product_sales: 1.75 },
    { "Product name": "Masala", total_product_sales: 2.5 },
    { "Product name": "Kashmiri Naan", total_product_sales: 5.11 },
    { "Product name": "Madras Coffee", total_product_sales: 5.49 },
    { "Product name": "Water Melon Juice", total_product_sales: 7.49 },
    { "Product name": "Hot & Sour Soup", total_product_sales: 7.99 },
    { "Product name": "Hot & Sour Veg Soup", total_product_sales: 7.99 },
    { "Product name": "Naan", total_product_sales: 8.83 },
    { "Product name": "Mixed Veg Pakoda", total_product_sales: 9.99 },
    { "Product name": "Pongal Vadai", total_product_sales: 11.99 },
    { "Product name": "Paneer Kulcha (1 pc)", total_product_sales: 12.0 },
    { "Product name": "Onion Kulcha", total_product_sales: 12.77 },
    { "Product name": "Dal Makhani", total_product_sales: 13.99 },
  ],
  "Product Summary US": [
    {
      "Product name": "South Indian Thali",
      Quantity: 437,
      Price: 19.0,
      "Total Product Sales": 8303.0,
    },
    {
      "Product name": "Idli",
      Quantity: 421,
      Price: 9.49,
      "Total Product Sales": 3995.29,
    },
    {
      "Product name": "Mini Tiffin",
      Quantity: 149,
      Price: 15.49,
      "Total Product Sales": 2308.01,
    },
    {
      "Product name": "Masala Dosai",
      Quantity: 165,
      Price: 14.49,
      "Total Product Sales": 2390.85,
    },
    {
      "Product name": "Vegetable Dum Biriyani",
      Quantity: 132,
      Price: 15.99,
      "Total Product Sales": 2110.68,
    },
    {
      "Product name": "Sambar Thatte Idly",
      Quantity: 106,
      Price: 12.99,
      "Total Product Sales": 1376.94,
    },
    {
      "Product name": "Mysore Masala Dosai",
      Quantity: 104,
      Price: 15.99,
      "Total Product Sales": 1662.96,
    },
    {
      "Product name": "Poori Masala",
      Quantity: 121,
      Price: 12.49,
      "Total Product Sales": 1511.29,
    },
    {
      "Product name": "Chole Bhature",
      Quantity: 86,
      Price: 15.99,
      "Total Product Sales": 1375.14,
    },
    {
      "Product name": "Parotta Kurma",
      Quantity: 85,
      Price: 14.49,
      "Total Product Sales": 1231.65,
    },
    {
      "Product name": "Masala Uthappam",
      Quantity: 72,
      Price: 14.99,
      "Total Product Sales": 1079.28,
    },
    {
      "Product name": "Idli Vadai",
      Quantity: 138,
      Price: 9.49,
      "Total Product Sales": 1309.62,
    },
    {
      "Product name": "Medhu Vadai",
      Quantity: 126,
      Price: 9.49,
      "Total Product Sales": 1195.74,
    },
    {
      "Product name": "Cocktail Sambar Idli",
      Quantity: 93,
      Price: 11.49,
      "Total Product Sales": 1068.57,
    },
    {
      "Product name": "Paneer Butter Masala",
      Quantity: 71,
      Price: 15.49,
      "Total Product Sales": 1099.79,
    },
    {
      "Product name": "Vegetable Fried Rice",
      Quantity: 49,
      Price: 15.99,
      "Total Product Sales": 783.51,
    },
    {
      "Product name": "Ghee Pongal",
      Quantity: 84,
      Price: 10.99,
      "Total Product Sales": 923.16,
    },
    {
      "Product name": "Chilli Bajji",
      Quantity: 70,
      Price: 9.99,
      "Total Product Sales": 699.3,
    },
    {
      "Product name": "Onion Rava Dosai",
      Quantity: 57,
      Price: 14.99,
      "Total Product Sales": 854.43,
    },
    {
      "Product name": "Sambar Vadai",
      Quantity: 63,
      Price: 10.49,
      "Total Product Sales": 660.87,
    },
    {
      "Product name": "Cauliflower 65",
      Quantity: 86,
      Price: 13.49,
      "Total Product Sales": 1160.14,
    },
    {
      "Product name": "Vegetable Noodles",
      Quantity: 61,
      Price: 15.99,
      "Total Product Sales": 975.39,
    },
    {
      "Product name": "Nei Podi Idli",
      Quantity: 51,
      Price: 11.99,
      "Total Product Sales": 611.49,
    },
    {
      "Product name": "South Indian Rush Lunch(Lunch Only)",
      Quantity: 36,
      Price: 15.99,
      "Total Product Sales": 575.64,
    },
    {
      "Product name": "Paneer Tikka",
      Quantity: 39,
      Price: 15.99,
      "Total Product Sales": 623.61,
    },
    {
      "Product name": "Plain Dosai",
      Quantity: 65,
      Price: 12.99,
      "Total Product Sales": 844.35,
    },
    {
      "Product name": "Paneer Chilli Fries",
      Quantity: 50,
      Price: 14.99,
      "Total Product Sales": 749.5,
    },
    {
      "Product name": "Paneer Khurchan",
      Quantity: 34,
      Price: 15.99,
      "Total Product Sales": 543.66,
    },
    {
      "Product name": "Vaazhaipoo Vadai",
      Quantity: 43,
      Price: 10.99,
      "Total Product Sales": 472.57,
    },
    {
      "Product name": "Kuzhi Paniyaram",
      Quantity: 36,
      Price: 13.49,
      "Total Product Sales": 485.64,
    },
    {
      "Product name": "Idiyappam (Dinner only)",
      Quantity: 42,
      Price: 13.99,
      "Total Product Sales": 587.58,
    },
    {
      "Product name": "Rava Masala Dosai",
      Quantity: 50,
      Price: 14.99,
      "Total Product Sales": 749.5,
    },
    {
      "Product name": "Szechwan Noodles",
      Quantity: 37,
      Price: 16.49,
      "Total Product Sales": 610.13,
    },
    {
      "Product name": "Curd Rice",
      Quantity: 74,
      Price: 11.49,
      "Total Product Sales": 850.26,
    },
    {
      "Product name": "Paneer Makhani",
      Quantity: 33,
      Price: 15.49,
      "Total Product Sales": 511.17,
    },
    {
      "Product name": "Baby Jackfruit Sukka",
      Quantity: 31,
      Price: 15.99,
      "Total Product Sales": 495.69,
    },
    {
      "Product name": "Paneer Masala Dosai",
      Quantity: 46,
      Price: 15.99,
      "Total Product Sales": 735.54,
    },
    {
      "Product name": "Ghee Roast",
      Quantity: 32,
      Price: 14.99,
      "Total Product Sales": 479.68,
    },
    {
      "Product name": "Mysore Rava  Masala Dosai",
      Quantity: 47,
      Price: 15.99,
      "Total Product Sales": 751.53,
    },
    {
      "Product name": "Appam",
      Quantity: 33,
      Price: 14.99,
      "Total Product Sales": 494.67,
    },
    {
      "Product name": "Chilli Parotta",
      Quantity: 44,
      Price: 15.49,
      "Total Product Sales": 681.56,
    },
    {
      "Product name": "Garlic Naan",
      Quantity: 108,
      Price: 4.49,
      "Total Product Sales": 484.92,
    },
    {
      "Product name": "Dal Makhani",
      Quantity: 36,
      Price: 13.99,
      "Total Product Sales": 503.64,
    },
    {
      "Product name": "Pineapple Rava Kesari",
      Quantity: 63,
      Price: 7.49,
      "Total Product Sales": 471.87,
    },
    {
      "Product name": "Tamarind Rice",
      Quantity: 35,
      Price: 11.49,
      "Total Product Sales": 402.15,
    },
    {
      "Product name": "Set Dosai Vadacurry (Dinner only)",
      Quantity: 34,
      Price: 14.49,
      "Total Product Sales": 492.66,
    },
    {
      "Product name": "Vegetable Pulao",
      Quantity: 31,
      Price: 14.99,
      "Total Product Sales": 464.69,
    },
    {
      "Product name": "Paneer 65",
      Quantity: 35,
      Price: 14.49,
      "Total Product Sales": 507.15,
    },
    {
      "Product name": "Paneer Pepper Fries",
      Quantity: 28,
      Price: 14.99,
      "Total Product Sales": 419.72,
    },
    {
      "Product name": "Masala Vadai",
      Quantity: 43,
      Price: 10.49,
      "Total Product Sales": 451.07,
    },
    {
      "Product name": "Kadai Paneer",
      Quantity: 30,
      Price: 15.49,
      "Total Product Sales": 464.7,
    },
    {
      "Product name": "Veg Kothu Parotta",
      Quantity: 29,
      Price: 15.49,
      "Total Product Sales": 449.21,
    },
    {
      "Product name": "Chapati Kurma",
      Quantity: 33,
      Price: 11.49,
      "Total Product Sales": 379.17,
    },
    {
      "Product name": "Chana Samosa",
      Quantity: 37,
      Price: 10.49,
      "Total Product Sales": 388.13,
    },
    {
      "Product name": "Dal Tadka",
      Quantity: 22,
      Price: 13.99,
      "Total Product Sales": 307.78,
    },
    {
      "Product name": "Babycorn Pepper Fries",
      Quantity: 39,
      Price: 13.99,
      "Total Product Sales": 545.61,
    },
    {
      "Product name": "Parotta",
      Quantity: 96,
      Price: 4.49,
      "Total Product Sales": 431.04,
    },
    {
      "Product name": "Cheese Dosai",
      Quantity: 22,
      Price: 13.99,
      "Total Product Sales": 307.78,
    },
    {
      "Product name": "Chapati",
      Quantity: 92,
      Price: 2.99,
      "Total Product Sales": 275.08,
    },
    {
      "Product name": "Rava Kichadi",
      Quantity: 45,
      Price: 10.49,
      "Total Product Sales": 472.05,
    },
    {
      "Product name": "Palak Paneer",
      Quantity: 19,
      Price: 15.49,
      "Total Product Sales": 294.31,
    },
    {
      "Product name": "Adai Aviyal (Dinner only)",
      Quantity: 22,
      Price: 14.99,
      "Total Product Sales": 329.78,
    },
    {
      "Product name": "Onion Uthappam",
      Quantity: 41,
      Price: 14.49,
      "Total Product Sales": 594.09,
    },
    {
      "Product name": "Szechwan Fried Rice",
      Quantity: 25,
      Price: 16.49,
      "Total Product Sales": 412.25,
    },
    {
      "Product name": "Plantain Bajji",
      Quantity: 27,
      Price: 9.99,
      "Total Product Sales": 269.73,
    },
    {
      "Product name": "Bisi Bele Bath",
      Quantity: 33,
      Price: 11.49,
      "Total Product Sales": 379.17,
    },
    {
      "Product name": "Rasam",
      Quantity: 43,
      Price: 7.99,
      "Total Product Sales": 343.57,
    },
    {
      "Product name": "Babycorn Chilli Fries",
      Quantity: 23,
      Price: 13.99,
      "Total Product Sales": 321.77,
    },
    {
      "Product name": "Paneer Fried Rice",
      Quantity: 19,
      Price: 16.99,
      "Total Product Sales": 322.81,
    },
    {
      "Product name": "Malai Kofta",
      Quantity: 27,
      Price: 15.49,
      "Total Product Sales": 418.23,
    },
    {
      "Product name": "Rava Dosai",
      Quantity: 22,
      Price: 14.49,
      "Total Product Sales": 318.78,
    },
    {
      "Product name": "Veg. Burnt Garlic Fried Rice",
      Quantity: 18,
      Price: 15.49,
      "Total Product Sales": 278.82,
    },
    {
      "Product name": "Vegetable Samosa",
      Quantity: 28,
      Price: 9.49,
      "Total Product Sales": 265.72,
    },
    {
      "Product name": "Paruppu Urundai Kuzhambu",
      Quantity: 20,
      Price: 14.99,
      "Total Product Sales": 299.8,
    },
    {
      "Product name": "Gongura Palaakaai Curry",
      Quantity: 12,
      Price: 15.49,
      "Total Product Sales": 185.88,
    },
    {
      "Product name": "Chana Masala",
      Quantity: 19,
      Price: 13.99,
      "Total Product Sales": 265.81,
    },
    {
      "Product name": "Mirapakaya Cauliflower",
      Quantity: 15,
      Price: 12.49,
      "Total Product Sales": 187.35,
    },
    {
      "Product name": "Kaai Kari Kurma",
      Quantity: 16,
      Price: 13.49,
      "Total Product Sales": 215.84,
    },
    {
      "Product name": "Gobi Manchurian Gravy ",
      Quantity: 19,
      Price: 13.49,
      "Total Product Sales": 256.31,
    },
    {
      "Product name": "Methi Malai Matar",
      Quantity: 15,
      Price: 15.99,
      "Total Product Sales": 239.85,
    },
    {
      "Product name": "Jigardhanda",
      Quantity: 25,
      Price: 12.99,
      "Total Product Sales": 324.75,
    },
    {
      "Product name": "Butter Naan",
      Quantity: 72,
      Price: 4.49,
      "Total Product Sales": 323.28,
    },
    {
      "Product name": "Keerai Vadai",
      Quantity: 17,
      Price: 10.49,
      "Total Product Sales": 178.33,
    },
    {
      "Product name": "Cutlet Chana",
      Quantity: 14,
      Price: 10.99,
      "Total Product Sales": 153.86,
    },
    {
      "Product name": "Paneer Pulao",
      Quantity: 11,
      Price: 15.99,
      "Total Product Sales": 175.89,
    },
    {
      "Product name": "Mushroom Chettinad",
      Quantity: 18,
      Price: 15.49,
      "Total Product Sales": 278.82,
    },
    {
      "Product name": "Ceylon Parotta",
      Quantity: 20,
      Price: 14.99,
      "Total Product Sales": 299.8,
    },
    {
      "Product name": "Aloo Bonda",
      Quantity: 25,
      Price: 9.99,
      "Total Product Sales": 249.75,
    },
    {
      "Product name": "Drumstick Soup",
      Quantity: 22,
      Price: 7.99,
      "Total Product Sales": 175.78,
    },
    {
      "Product name": "Bhel Puri",
      Quantity: 17,
      Price: 9.49,
      "Total Product Sales": 161.33,
    },
    {
      "Product name": "Paneer Mughlai",
      Quantity: 18,
      Price: 15.99,
      "Total Product Sales": 287.82,
    },
    {
      "Product name": "Paneer Manchurian Gravy ",
      Quantity: 14,
      Price: 13.99,
      "Total Product Sales": 195.86,
    },
    {
      "Product name": "Tricolor Paneer Tikka",
      Quantity: 10,
      Price: 14.99,
      "Total Product Sales": 149.9,
    },
    {
      "Product name": "Roti",
      Quantity: 46,
      Price: 3.99,
      "Total Product Sales": 183.54,
    },
    {
      "Product name": "Ennai Kathirikai Kuzhambu",
      Quantity: 10,
      Price: 14.99,
      "Total Product Sales": 149.9,
    },
    {
      "Product name": "Pongal Vadai",
      Quantity: 15,
      Price: 11.99,
      "Total Product Sales": 179.85,
    },
    {
      "Product name": "Vegetable Cutlet",
      Quantity: 19,
      Price: 9.99,
      "Total Product Sales": 189.81,
    },
    {
      "Product name": "Shabnam Dharnam",
      Quantity: 11,
      Price: 14.99,
      "Total Product Sales": 164.89,
    },
    {
      "Product name": "Lemon Rice",
      Quantity: 17,
      Price: 11.49,
      "Total Product Sales": 195.33,
    },
    {
      "Product name": "Dal Palak",
      Quantity: 14,
      Price: 13.99,
      "Total Product Sales": 195.86,
    },
    {
      "Product name": "Punugulu",
      Quantity: 22,
      Price: 9.49,
      "Total Product Sales": 208.78,
    },
    {
      "Product name": "Matar Paneer",
      Quantity: 11,
      Price: 15.49,
      "Total Product Sales": 170.39,
    },
    {
      "Product name": "Paper Roast",
      Quantity: 13,
      Price: 16.49,
      "Total Product Sales": 214.37,
    },
    {
      "Product name": "Cauliflower Chilli Fries",
      Quantity: 11,
      Price: 13.99,
      "Total Product Sales": 153.89,
    },
    {
      "Product name": "Kadai Vegatables",
      Quantity: 15,
      Price: 14.99,
      "Total Product Sales": 224.85,
    },
    {
      "Product name": "Mushroom Chilli Fries",
      Quantity: 15,
      Price: 13.99,
      "Total Product Sales": 209.85,
    },
    {
      "Product name": "Chota Samosa",
      Quantity: 23,
      Price: 9.49,
      "Total Product Sales": 218.27,
    },
    {
      "Product name": "Veg Spring Rolls",
      Quantity: 10,
      Price: 11.49,
      "Total Product Sales": 114.9,
    },
    {
      "Product name": "Rasamalai",
      Quantity: 23,
      Price: 6.49,
      "Total Product Sales": 149.27,
    },
    {
      "Product name": "Veg. Thai Noodles",
      Quantity: 8,
      Price: 15.99,
      "Total Product Sales": 127.92,
    },
    {
      "Product name": "Vegetable Jalfrezi",
      Quantity: 11,
      Price: 14.99,
      "Total Product Sales": 164.89,
    },
    {
      "Product name": "Mushroom Pepper Fries",
      Quantity: 10,
      Price: 13.99,
      "Total Product Sales": 139.9,
    },
    {
      "Product name": "Lachha Parotta",
      Quantity: 30,
      Price: 5.49,
      "Total Product Sales": 164.7,
    },
    {
      "Product name": "Special Jack Fruit Biriyani (Saturday Lunch only)",
      Quantity: 7,
      Price: 16.99,
      "Total Product Sales": 118.93,
    },
    {
      "Product name": "Kids Special Chocolate Dosai",
      Quantity: 12,
      Price: 13.49,
      "Total Product Sales": 161.88,
    },
    {
      "Product name": "Podi Uthappam",
      Quantity: 9,
      Price: 13.99,
      "Total Product Sales": 125.91,
    },
    {
      "Product name": "Mushroom 65",
      Quantity: 8,
      Price: 13.49,
      "Total Product Sales": 107.92,
    },
    {
      "Product name": "Onion Rava Masala Dosa",
      Quantity: 7,
      Price: 15.99,
      "Total Product Sales": 111.93,
    },
    {
      "Product name": "Koon Curry",
      Quantity: 10,
      Price: 15.49,
      "Total Product Sales": 154.9,
    },
    {
      "Product name": "Sweet Corn Veg Soup",
      Quantity: 16,
      Price: 7.99,
      "Total Product Sales": 127.84,
    },
    {
      "Product name": "Onion Pakoda",
      Quantity: 14,
      Price: 9.99,
      "Total Product Sales": 139.86,
    },
    {
      "Product name": "Mysore Rava Dosai",
      Quantity: 8,
      Price: 14.99,
      "Total Product Sales": 119.92,
    },
    {
      "Product name": "Peas Pulao",
      Quantity: 8,
      Price: 14.99,
      "Total Product Sales": 119.92,
    },
    {
      "Product name": "Podi Dosai",
      Quantity: 7,
      Price: 13.99,
      "Total Product Sales": 97.93,
    },
    {
      "Product name": "Gulab Jamun",
      Quantity: 19,
      Price: 6.49,
      "Total Product Sales": 123.31,
    },
    {
      "Product name": "Cream of Tomato Soup",
      Quantity: 20,
      Price: 7.99,
      "Total Product Sales": 159.8,
    },
    {
      "Product name": "Onion Dosai",
      Quantity: 8,
      Price: 13.99,
      "Total Product Sales": 111.92,
    },
    {
      "Product name": "Chilly Idly",
      Quantity: 10,
      Price: 13.49,
      "Total Product Sales": 134.9,
    },
    {
      "Product name": "Mixed Veg Pakoda",
      Quantity: 17,
      Price: 9.99,
      "Total Product Sales": 169.83,
    },
    {
      "Product name": "Aloo Paratha (Wheat)",
      Quantity: 23,
      Price: 4.49,
      "Total Product Sales": 103.27,
    },
    {
      "Product name": "Karuveppilai Poondu Kuzhambu",
      Quantity: 7,
      Price: 14.99,
      "Total Product Sales": 104.93,
    },
    {
      "Product name": "Hot & Sour Veg Soup",
      Quantity: 18,
      Price: 7.99,
      "Total Product Sales": 143.82,
    },
    {
      "Product name": "Impossible Veg Fried Rice",
      Quantity: 5,
      Price: 16.99,
      "Total Product Sales": 84.95,
    },
    {
      "Product name": "Bhindi Masala",
      Quantity: 8,
      Price: 14.99,
      "Total Product Sales": 119.92,
    },
    {
      "Product name": "Matar Mushroom",
      Quantity: 5,
      Price: 14.99,
      "Total Product Sales": 74.95,
    },
    {
      "Product name": "Jeera Rice",
      Quantity: 10,
      Price: 13.49,
      "Total Product Sales": 134.9,
    },
    {
      "Product name": "Small Onion Uthappam",
      Quantity: 5,
      Price: 14.99,
      "Total Product Sales": 74.95,
    },
    {
      "Product name": "Potato Bajji",
      Quantity: 7,
      Price: 9.99,
      "Total Product Sales": 69.93,
    },
    {
      "Product name": "Mango Lassi",
      Quantity: 9,
      Price: 6.99,
      "Total Product Sales": 62.91,
    },
    {
      "Product name": "Mysore Dosai",
      Quantity: 4,
      Price: 14.49,
      "Total Product Sales": 57.96,
    },
    {
      "Product name": "Kadai Tofu with Brussels Sprouts",
      Quantity: 6,
      Price: 15.99,
      "Total Product Sales": 95.94,
    },
    {
      "Product name": "Chilli Garlic Naan",
      Quantity: 20,
      Price: 4.49,
      "Total Product Sales": 89.8,
    },
    {
      "Product name": "Hot Garlic Tofu Bok Choy Stir Fry",
      Quantity: 7,
      Price: 15.99,
      "Total Product Sales": 111.93,
    },
    {
      "Product name": "Aloo Matar",
      Quantity: 7,
      Price: 14.99,
      "Total Product Sales": 104.93,
    },
    {
      "Product name": "Paneer Paratha (Wheat)",
      Quantity: 11,
      Price: 5.49,
      "Total Product Sales": 60.39,
    },
    {
      "Product name": "Dahi Vadai",
      Quantity: 5,
      Price: 10.49,
      "Total Product Sales": 52.45,
    },
    {
      "Product name": "Kadai Mushroom",
      Quantity: 6,
      Price: 14.99,
      "Total Product Sales": 89.94,
    },
    {
      "Product name": "Lychee Paneer",
      Quantity: 5,
      Price: 15.99,
      "Total Product Sales": 79.95,
    },
    {
      "Product name": "Plain Uthappam",
      Quantity: 17,
      Price: 13.49,
      "Total Product Sales": 229.33,
    },
    {
      "Product name": "Paneer Paratha",
      Quantity: 11,
      Price: 5.49,
      "Total Product Sales": 60.39,
    },
    {
      "Product name": "Naan",
      Quantity: 27,
      Price: 3.99,
      "Total Product Sales": 107.73,
    },
    {
      "Product name": "Kal Dosai",
      Quantity: 5,
      Price: 12.99,
      "Total Product Sales": 64.95,
    },
    {
      "Product name": "Idiyappam With Mushroom Paya (Weekend Only)",
      Quantity: 3,
      Price: 15.49,
      "Total Product Sales": 46.47,
    },
    {
      "Product name": "Mushroom Fried Rice",
      Quantity: 4,
      Price: 16.99,
      "Total Product Sales": 67.96,
    },
    {
      "Product name": "Kashmiri Naan",
      Quantity: 9,
      Price: 4.99,
      "Total Product Sales": 44.91,
    },
    {
      "Product name": "Onion Kulcha",
      Quantity: 10,
      Price: 5.49,
      "Total Product Sales": 54.9,
    },
    {
      "Product name": "Aloo Kulcha",
      Quantity: 6,
      Price: 5.99,
      "Total Product Sales": 35.94,
    },
    {
      "Product name": "Sundaikai Vathal Kuzhambu",
      Quantity: 6,
      Price: 14.99,
      "Total Product Sales": 89.94,
    },
    {
      "Product name": "Mushroom Pulao",
      Quantity: 5,
      Price: 15.99,
      "Total Product Sales": 79.95,
    },
    {
      "Product name": "French Fries",
      Quantity: 6,
      Price: 9.99,
      "Total Product Sales": 59.94,
    },
    {
      "Product name": "Navratan Korma",
      Quantity: 5,
      Price: 14.99,
      "Total Product Sales": 74.95,
    },
    {
      "Product name": "Chilli Cheese Naan",
      Quantity: 7,
      Price: 5.49,
      "Total Product Sales": 38.43,
    },
    {
      "Product name": "Curry Leaf Mushroom Curry",
      Quantity: 3,
      Price: 15.49,
      "Total Product Sales": 46.47,
    },
    {
      "Product name": "Poondu Dosai (Garlic)",
      Quantity: 2,
      Price: 14.99,
      "Total Product Sales": 29.98,
    },
    {
      "Product name": "Madras Filter Coffee",
      Quantity: 5,
      Price: 5.49,
      "Total Product Sales": 27.45,
    },
    {
      "Product name": "Royal Falooda",
      Quantity: 2,
      Price: 13.49,
      "Total Product Sales": 26.98,
    },
    {
      "Product name": "Onion Bajji",
      Quantity: 6,
      Price: 9.99,
      "Total Product Sales": 59.94,
    },
    {
      "Product name": "Rasam Vadai",
      Quantity: 2,
      Price: 9.99,
      "Total Product Sales": 19.98,
    },
    {
      "Product name": "Impossible Vegetable Omelette",
      Quantity: 2,
      Price: 9.99,
      "Total Product Sales": 19.98,
    },
    {
      "Product name": "Kashmiri Pulao",
      Quantity: 2,
      Price: 15.99,
      "Total Product Sales": 31.98,
    },
    {
      "Product name": "Veg. Tricolor Fried Rice",
      Quantity: 2,
      Price: 15.99,
      "Total Product Sales": 31.98,
    },
    {
      "Product name": "Sweet Of the Day",
      Quantity: 7,
      Price: 6.99,
      "Total Product Sales": 48.93,
    },
    {
      "Product name": "Impossible Vegetable Laba",
      Quantity: 1,
      Price: 16.99,
      "Total Product Sales": 16.99,
    },
    {
      "Product name": "Mirapakaya Paneer",
      Quantity: 4,
      Price: 13.49,
      "Total Product Sales": 53.96,
    },
    {
      "Product name": "Masala Tea",
      Quantity: 3,
      Price: 5.49,
      "Total Product Sales": 16.47,
    },
    {
      "Product name": "Mushroom Manchurian Gravy",
      Quantity: 3,
      Price: 13.49,
      "Total Product Sales": 40.47,
    },
    {
      "Product name": "Chikku Milkshake",
      Quantity: 2,
      Price: 7.99,
      "Total Product Sales": 15.98,
    },
    {
      "Product name": "Sweet Paan",
      Quantity: 6,
      Price: 2.45,
      "Total Product Sales": 14.7,
    },
    {
      "Product name": "Ragi (Finger) Millet Dosai",
      Quantity: 2,
      Price: 14.49,
      "Total Product Sales": 28.98,
    },
    {
      "Product name": "Paneer Kulcha",
      Quantity: 6,
      Price: 5.99,
      "Total Product Sales": 35.94,
    },
    {
      "Product name": "Water Melon Juice",
      Quantity: 1,
      Price: 7.49,
      "Total Product Sales": 7.49,
    },
    {
      "Product name": "Masala",
      Quantity: 1,
      Price: 2.5,
      "Total Product Sales": 2.5,
    },
    {
      "Product name": "Onion Masala Dosai",
      Quantity: 1,
      Price: 14.49,
      "Total Product Sales": 14.49,
    },
  ],
  "Top Voided Items": [
    { name: "Idli", void_items: 8 },
    { name: "Cauliflower 65", void_items: 6 },
    { name: "Keerai Vadai", void_items: 5 },
    { name: "South Indian Thali", void_items: 5 },
    { name: "Chapati", void_items: 4 },
    { name: "Chole Bhature", void_items: 4 },
    { name: "Onion Bajji", void_items: 4 },
    { name: "South Indian Rush Lunch(Lunch Only)", void_items: 4 },
    { name: "Bisi Bele Bath", void_items: 3 },
    { name: "Idiyappam (Dinner only)", void_items: 3 },
    { name: "Mysore Masala Dosai", void_items: 3 },
    { name: "Paneer Mughlai", void_items: 3 },
    { name: "Cocktail Sambar Idli", void_items: 2 },
    { name: "Gobi Manchurian Gravy ", void_items: 2 },
    { name: "Idli Vadai", void_items: 2 },
    { name: "Kadai Mushroom", void_items: 2 },
    { name: "Kadai Paneer", void_items: 2 },
    { name: "Mini Tiffin", void_items: 2 },
    { name: "Onion Pakoda", void_items: 2 },
    { name: "Paneer Makhani", void_items: 2 },
    { name: "Parotta Kurma", void_items: 2 },
    { name: "Plain Dosai", void_items: 2 },
    { name: "Rava Kichadi", void_items: 2 },
    { name: "Szechwan Fried Rice", void_items: 2 },
    { name: "Szechwan Noodles", void_items: 2 },
    { name: "Vegetable Dum Biriyani", void_items: 2 },
    { name: "Vegetable Noodles", void_items: 2 },
    { name: "Aloo Paratha (Wheat)", void_items: 1 },
    { name: "Baby Jackfruit Sukka", void_items: 1 },
    { name: "Babycorn Chilli Fries", void_items: 1 },
    { name: "Babycorn Pepper Fries", void_items: 1 },
    { name: "Butter Naan", void_items: 1 },
    { name: "Chikku Milkshake", void_items: 1 },
    { name: "Cream of Tomato Soup", void_items: 1 },
    { name: "Curd Rice", void_items: 1 },
    { name: "Drumstick Soup", void_items: 1 },
    { name: "Garlic Naan", void_items: 1 },
    { name: "Ghee Pongal", void_items: 1 },
    { name: "Hot & Sour Veg Soup", void_items: 1 },
    { name: "Hot Garlic Tofu Bok Choy Stir Fry", void_items: 1 },
    { name: "Impossible Schezwan Fried Rice", void_items: 1 },
    { name: "Jeera Rice", void_items: 1 },
    { name: "Jigardhanda", void_items: 1 },
    { name: "Kaai Kari Kurma", void_items: 1 },
    { name: "Kadai Vegatables", void_items: 1 },
    { name: "Lachha Parotta", void_items: 1 },
    { name: "Madras Filter Coffee", void_items: 1 },
    { name: "Malai Kofta", void_items: 1 },
    { name: "Masala Uthappam", void_items: 1 },
    { name: "Medhu Vadai", void_items: 1 },
    { name: "Mirapakaya Cauliflower", void_items: 1 },
    { name: "Mushroom Chettinad", void_items: 1 },
    { name: "Mushroom Pulao", void_items: 1 },
    { name: "Naan", void_items: 1 },
    { name: "Paneer Chilli Fries", void_items: 1 },
    { name: "Paneer Masala Dosai", void_items: 1 },
    { name: "Paneer Pulao", void_items: 1 },
    { name: "Paneer Tikka", void_items: 1 },
    { name: "Parotta", void_items: 1 },
    { name: "Pineapple Rava Kesari", void_items: 1 },
    { name: "Punugulu", void_items: 1 },
    { name: "Rasamalai", void_items: 1 },
    { name: "Rava Dosai", void_items: 1 },
    { name: "Rava Masala Dosai", void_items: 1 },
    { name: "Roti", void_items: 1 },
    { name: "Royal Falooda", void_items: 1 },
    { name: "Sambar Vadai", void_items: 1 },
    { name: "Set Dosai Vadacurry (Dinner only)", void_items: 1 },
    { name: "Sweet Corn Veg Soup", void_items: 1 },
    { name: "Veg Kothu Parotta", void_items: 1 },
    { name: "Vegetable Fried Rice", void_items: 1 },
  ],
  "Top Cancel Reasons": [
    { cancel_rsn: "UNKNOWN", void_items: 107 },
    { cancel_rsn: "REFUND", void_items: 9 },
    { cancel_rsn: "ORDER PLACE IN WRONG BRANCH", void_items: 6 },
    { cancel_rsn: "OTHERS", void_items: 1 },
    { cancel_rsn: "MISSING ITEM", void_items: 1 },
    { cancel_rsn: "MANAGER", void_items: 1 },
    { cancel_rsn: "ITEMS NOT AVAILABLE", void_items: 1 },
  ],
};
