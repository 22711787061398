export const checkInD = {
  Cancellations: [{ count: 15 }],
  "Daily Guest": [{ count: 52821 }],
  "Daily Checkin": [{ count: 11616 }],
  "Day Over Day Guests One": [
    { x: "January 1, 2025", y: 400, type: "checkIns" },
    { x: "January 1, 2025", y: 600, type: "guest" },
    { x: "February 1, 2025", y: 550, type: "checkIns" },
    { x: "February 1, 2025", y: 700, type: "guest" },
    { x: "March 1, 2025", y: 300, type: "checkIns" },
    { x: "March 1, 2025", y: 800, type: "guest" },
    { x: "April 1, 2025", y: 450, type: "checkIns" },
    { x: "April 1, 2025", y: 900, type: "guest" },
    { x: "May 1, 2025", y: 600, type: "checkIns" },
    { x: "May 1, 2025", y: 1000, type: "guest" },
  ],
  "Daily Hourly Guest": [
    { reservation_time: "18:00", channel_name: "ONLINE", count: 440 },
    { reservation_time: "18:30", channel_name: "ONLINE", count: 1189 },
    { reservation_time: "19:00", channel_name: "MERCHANT", count: 4 },
    { reservation_time: "19:00", channel_name: "ONLINE", count: 2838 },
    { reservation_time: "19:30", channel_name: "MERCHANT", count: 2 },
    { reservation_time: "19:30", channel_name: "ONLINE", count: 5121 },
    { reservation_time: "20:00", channel_name: "MERCHANT", count: 26 },
    { reservation_time: "20:00", channel_name: "ONLINE", count: 7034 },
    { reservation_time: "20:30", channel_name: "ONLINE", count: 7444 },
    { reservation_time: "21:00", channel_name: "MERCHANT", count: 8 },
    { reservation_time: "21:00", channel_name: "ONLINE", count: 5493 },
    { reservation_time: "21:30", channel_name: "MERCHANT", count: 21 },
    { reservation_time: "21:30", channel_name: "EMPTY", count: 2657 },
    { reservation_time: "22:00", channel_name: "EMPTY", count: 819 },
    { reservation_time: "22:30", channel_name: "EMPTY", count: 270 },
    { reservation_time: "23:00", channel_name: "EMPTY", count: 23 },
  ],
  "Daily Hourly CheckIn": [
    { reservation_time: "16:00", channel_name: "ONLINE", count: 101 },
    { reservation_time: "16:30", channel_name: "ONLINE", count: 20 },
    { reservation_time: "17:30", channel_name: "ONLINE", count: 65 },
    { reservation_time: "18:00", channel_name: "ONLINE", count: 127 },
    { reservation_time: "18:30", channel_name: "ONLINE", count: 269 },
    { reservation_time: "19:00", channel_name: "MERCHANT", count: 1 },
    { reservation_time: "19:00", channel_name: "ONLINE", count: 586 },
    { reservation_time: "19:30", channel_name: "MERCHANT", count: 2 },
    { reservation_time: "19:30", channel_name: "ONLINE", count: 1042 },
    { reservation_time: "20:00", channel_name: "MERCHANT", count: 5 },
    { reservation_time: "20:00", channel_name: "ONLINE", count: 1448 },
    { reservation_time: "20:30", channel_name: "ONLINE", count: 1559 },
    { reservation_time: "21:00", channel_name: "MERCHANT", count: 3 },
    { reservation_time: "21:00", channel_name: "ONLINE", count: 1193 },
    { reservation_time: "21:30", channel_name: "MERCHANT", count: 5 },
    { reservation_time: "21:30", channel_name: "EMPTY", count: 597 },
    { reservation_time: "22:00", channel_name: "EMPTY", count: 193 },
    { reservation_time: "22:30", channel_name: "EMPTY", count: 52 },
    { reservation_time: "23:00", channel_name: "EMPTY", count: 10 },
  ],
  "Daily CheckIn Details": [
    {
      "CheckIn Time": "2023-08-02T11:56:02-04:00",
      "CheckIn #": "9795",
      "Guest Name": "Harshil ",
      "Guest Size": 1,
      Phone: "+1-3126187608",
      Channel: "ONLINE",
      Status: "Checked Out",
    },
    {
      "CheckIn Time": "2023-08-01T21:03:11-04:00",
      "CheckIn #": "9794",
      "Guest Name": "Sanjeev ",
      "Guest Size": 1,
      Phone: "+1-3474033260",
      Channel: "ONLINE",
      Status: "Checked Out",
    },
    {
      "CheckIn Time": "2023-08-01T20:32:46-04:00",
      "CheckIn #": "9793",
      "Guest Name": "Abhimanyu",
      "Guest Size": 1,
      Phone: "+1-9145252432",
      Channel: "ONLINE",
      Status: "Checked Out",
    },
    {
      "CheckIn Time": "2023-08-01T20:22:18-04:00",
      "CheckIn #": "9792",
      "Guest Name": "Nilesh",
      "Guest Size": 2,
      Phone: "+1-8624770152",
      Channel: "ONLINE",
      Status: "Checked Out",
    },
    {
      "CheckIn Time": "2023-08-01T20:19:36-04:00",
      "CheckIn #": "9791",
      "Guest Name": "Ramesh",
      "Guest Size": 4,
      Phone: "+1-8187261387",
      Channel: "ONLINE",
      Status: "Checked Out",
    },
    {
      "CheckIn Time": "2023-08-01T20:04:34-04:00",
      "CheckIn #": "9790",
      "Guest Name": "Chinmoy Chakrabarty ",
      "Guest Size": 3,
      Phone: "+1-9729436332",
      Channel: "ONLINE",
      Status: "Checked Out",
    },
    {
      "CheckIn Time": "2023-08-01T19:51:47-04:00",
      "CheckIn #": "9789",
      "Guest Name": "Yash",
      "Guest Size": 5,
      Phone: "+1-9735583955",
      Channel: "ONLINE",
      Status: "Checked Out",
    },
    {
      "CheckIn Time": "2023-08-01T19:45:54-04:00",
      "CheckIn #": "9788",
      "Guest Name": "Amar",
      "Guest Size": 2,
      Phone: "+1-9494395458",
      Channel: "ONLINE",
      Status: "Checked Out",
    },
    {
      "CheckIn Time": "2023-08-01T18:52:25-04:00",
      "CheckIn #": "9787",
      "Guest Name": "Karan",
      "Guest Size": 1,
      Phone: "+1-7324473891",
      Channel: "ONLINE",
      Status: "Checked Out",
    },
    {
      "CheckIn Time": "2023-08-01T18:50:26-04:00",
      "CheckIn #": "9786",
      "Guest Name": "Samir Ruparel",
      "Guest Size": 4,
      Phone: "+1-7324853331",
      Channel: "ONLINE",
      Status: "Checked Out",
    }
  ],
  "Day Over Day Guests": [
    {
      Name: "A2B Indian Veg Restaurant, Illinois",
      reservation_dt: "2023-08-01T00:00:00-04:00",
      Guests: 75,
    },
    {
      Name: "A2B Indian Veg Restaurant, Illinois",
      reservation_dt: "2023-08-02T00:00:00-04:00",
      Guests: 5,
    },
    {
      Name: "A2B Indian Veg Restaurant, Illinois",
      reservation_dt: "2023-08-03T00:00:00-04:00",
      Guests: 274,
    },
    {
      Name: "A2B Indian Veg Restaurant, Illinois",
      reservation_dt: "2023-08-04T00:00:00-04:00",
      Guests: 499,
    },
    {
      Name: "A2B Indian Veg Restaurant, Illinois",
      reservation_dt: "2023-08-05T00:00:00-04:00",
      Guests: 1242,
    },
    {
      Name: "A2B Indian Veg Restaurant, Illinois",
      reservation_dt: "2023-08-06T00:00:00-04:00",
      Guests: 694,
    },
    {
      Name: "A2B Indian Veg Restaurant, Illinois",
      reservation_dt: "2023-08-08T00:00:00-04:00",
      Guests: 258,
    },
    {
      Name: "A2B Indian Veg Restaurant, Illinois",
      reservation_dt: "2023-08-10T00:00:00-04:00",
      Guests: 244,
    },
    {
      Name: "A2B Indian Veg Restaurant, Illinois",
      reservation_dt: "2023-08-11T00:00:00-04:00",
      Guests: 360,
    },
    {
      Name: "A2B Indian Veg Restaurant, Illinois",
      reservation_dt: "2023-08-12T00:00:00-04:00",
      Guests: 879,
    },
    {
      Name: "A2B Indian Veg Restaurant, Illinois",
      reservation_dt: "2023-08-13T00:00:00-04:00",
      Guests: 1067,
    },
    {
      Name: "A2B Indian Veg Restaurant, Illinois",
      reservation_dt: "2023-08-15T00:00:00-04:00",
      Guests: 34,
    },
    {
      Name: "A2B Indian Veg Restaurant, Illinois",
      reservation_dt: "2023-08-16T00:00:00-04:00",
      Guests: 123,
    },
    {
      Name: "A2B Indian Veg Restaurant, Illinois",
      reservation_dt: "2023-08-17T00:00:00-04:00",
      Guests: 58,
    },
    {
      Name: "A2B Indian Veg Restaurant, Illinois",
      reservation_dt: "2023-08-18T00:00:00-04:00",
      Guests: 546,
    },
  ],
  "Reservation Data": [
    {
      partyName: "Smith Family",
      partyPhone: "555-1212",
      partySize: 6,
      kidsSize: 2,
      highChairSize: 1,
      seatTogether: true,
      channelName: "Phone",
      notes: "Birthday celebration",
      confirmationNo: "ABC1234",
      reservationTime: "2024-03-15T19:00:00", // ISO 8601 format for dates and times
      cancellationTime: "2024-03-15T17:00:00",
      actualDineInTime: "2024-03-15T19:15:00",
      actualDineOutTime: "2024-03-15T21:00:00",
      notifyParty: true,
      reservationStatus: "Seated", // or "Confirmed", "Cancelled", "No-Show", etc.
    },
    {
      partyName: "Jones & Friends",
      partyPhone: "555-5678",
      partySize: 4,
      kidsSize: 0,
      highChairSize: 0,
      seatTogether: true,
      channelName: "Online",
      notes: "Anniversary dinner",
      confirmationNo: "XYZ5678",
      reservationTime: "2024-03-16T18:30:00",
      cancellationTime: "2024-03-16T16:30:00",
      actualDineInTime: "2024-03-16T18:45:00",
      actualDineOutTime: "2024-03-16T20:30:00",
      notifyParty: false,
      reservationStatus: "Confirmed",
    },
    {
      partyName: "Brown Corp",
      partyPhone: "555-9012",
      partySize: 10,
      kidsSize: 3,
      highChairSize: 2,
      seatTogether: false,
      channelName: "Email",
      notes: "Corporate event",
      confirmationNo: "PQR9012",
      reservationTime: "2024-03-17T20:00:00",
      cancellationTime: "2024-03-17T18:00:00",
      actualDineInTime: null, // Not yet dined in
      actualDineOutTime: null, // Not yet dined out
      notifyParty: true,
      reservationStatus: "Pending",
    },
    // Add more mock reservation objects as needed
    {
      partyName: "Wilson Family",
      partyPhone: "555-1314",
      partySize: 5,
      kidsSize: 1,
      highChairSize: 1,
      seatTogether: true,
      channelName: "Walk-in",
      notes: "",
      confirmationNo: "WXY1314",
      reservationTime: "2024-03-18T17:30:00",
      cancellationTime: null, // Walk-ins don't have cancellation times
      actualDineInTime: "2024-03-18T17:40:00",
      actualDineOutTime: "2024-03-18T19:00:00",
      notifyParty: false,
      reservationStatus: "Seated"
    }
  ],
  "Daily Dine In Time": [
    { party_size: 1, "Peak Time": "Off Peak", "Dine Tine": 2617 },
    { party_size: 1, "Peak Time": "Peak", "Dine Tine": 3011 },
    { party_size: 2, "Peak Time": "Off Peak", "Dine Tine": 2772 },
    { party_size: 2, "Peak Time": "Peak", "Dine Tine": 3020 },
    { party_size: 3, "Peak Time": "Off Peak", "Dine Tine": 2293 },
    { party_size: 3, "Peak Time": "Peak", "Dine Tine": 3412 },
    { party_size: 4, "Peak Time": "Off Peak", "Dine Tine": 2518 },
    { party_size: 4, "Peak Time": "Peak", "Dine Tine": 3127 },
    { party_size: 5, "Peak Time": "Off Peak", "Dine Tine": 3582 },
    { party_size: 5, "Peak Time": "Peak", "Dine Tine": 4249 },
    { party_size: 6, "Peak Time": "Off Peak", "Dine Tine": 3639 },
    { party_size: 6, "Peak Time": "Peak", "Dine Tine": 4001 },
    { party_size: 7, "Peak Time": "Off Peak", "Dine Tine": 3311 },
    { party_size: 7, "Peak Time": "Peak", "Dine Tine": 4783 },
    { party_size: 8, "Peak Time": "Off Peak", "Dine Tine": 4163 },
    { party_size: 8, "Peak Time": "Peak", "Dine Tine": 3977 },
    { party_size: 9, "Peak Time": "Off Peak", "Dine Tine": 2445 },
    { party_size: 9, "Peak Time": "Peak", "Dine Tine": 4412 },
    { party_size: 10, "Peak Time": "Off Peak", "Dine Tine": 11047 },
    { party_size: 10, "Peak Time": "Peak", "Dine Tine": 4499 },
    { party_size: 11, "Peak Time": "Off Peak", "Dine Tine": 4542 },
    { party_size: 11, "Peak Time": "Peak", "Dine Tine": 4399 },
    { party_size: 12, "Peak Time": "Off Peak", "Dine Tine": 3717 },
    { party_size: 12, "Peak Time": "Peak", "Dine Tine": 6863 },
    { party_size: 13, "Peak Time": "Off Peak", "Dine Tine": 2885 },
    { party_size: 13, "Peak Time": "Peak", "Dine Tine": 5911 },
    { party_size: 14, "Peak Time": "Off Peak", "Dine Tine": 2093 },
    { party_size: 14, "Peak Time": "Peak", "Dine Tine": 5712 },
    { party_size: 15, "Peak Time": "Off Peak", "Dine Tine": 6049 },
    { party_size: 15, "Peak Time": "Peak", "Dine Tine": 1073 },
    { party_size: 16, "Peak Time": "Off Peak", "Dine Tine": 2103 },
    { party_size: 16, "Peak Time": "Peak", "Dine Tine": 5766 },
    { party_size: 17, "Peak Time": "Off Peak", "Dine Tine": 6087 },
    { party_size: 17, "Peak Time": "Peak", "Dine Tine": 998 },
    { party_size: 18, "Peak Time": "Off Peak", "Dine Tine": 1523 },
    { party_size: 18, "Peak Time": "Peak", "Dine Tine": 19934 },
    { party_size: 19, "Peak Time": "Off Peak", "Dine Tine": null },
    { party_size: 19, "Peak Time": "Peak", "Dine Tine": null },
    { party_size: 20, "Peak Time": "Off Peak", "Dine Tine": 6000 },
    { party_size: 20, "Peak Time": "Peak", "Dine Tine": 304 },
    { party_size: 21, "Peak Time": "Off Peak", "Dine Tine": 2796 },
    { party_size: 21, "Peak Time": "Peak", "Dine Tine": 24566 },
    { party_size: 22, "Peak Time": "Peak", "Dine Tine": null },
  ],
  "Party Size Distributions": [
    { "Party Size": 1, count: 1335 },
    { "Party Size": 2, count: 2210 },
    { "Party Size": 3, count: 1233 },
    { "Party Size": 4, count: 2243 },
    { "Party Size": 5, count: 833 },
    { "Party Size": 6, count: 1737 },
    { "Party Size": 7, count: 452 },
    { "Party Size": 8, count: 599 },
    { "Party Size": 9, count: 182 },
    { "Party Size": 10, count: 274 },
    { "Party Size": 11, count: 79 },
    { "Party Size": 12, count: 176 },
    { "Party Size": 13, count: 54 },
    { "Party Size": 14, count: 58 },
    { "Party Size": 15, count: 30 },
    { "Party Size": 16, count: 36 },
    { "Party Size": 17, count: 13 },
    { "Party Size": 18, count: 21 },
    { "Party Size": 19, count: 5 },
    { "Party Size": 20, count: 15 },
    { "Party Size": 21, count: 6 },
    { "Party Size": 22, count: 4 },
    { "Party Size": 23, count: 1 },
    { "Party Size": 24, count: 8 },
  ],
  "Total Customers": [{ "Distinct values of Party Phone": 21295 }],
  "Repeat Customers Count": [{ "count(*)": 6134 }],
  "Repeat Customers": [
    {
      "Customer Name": "Ruchi Agarwal",
      "Customer Phone#": "+1-9722156927",
      "#Visits": 6,
    },
    {
      "Customer Name": "Bhavi Trivedi",
      "Customer Phone#": "+1-8628884830",
      "#Visits": 6,
    },
    {
      "Customer Name": "Pratap",
      "Customer Phone#": "+1-2012458054",
      "#Visits": 6,
    },
    {
      "Customer Name": "Ravi Shekhar",
      "Customer Phone#": "+1-2018794186",
      "#Visits": 6,
    },
    {
      "Customer Name": "Raghu",
      "Customer Phone#": "+1-2013601947",
      "#Visits": 6,
    },
    {
      "Customer Name": "Mandar",
      "Customer Phone#": "+1-9177510335",
      "#Visits": 6,
    },
    {
      "Customer Name": "Ambika",
      "Customer Phone#": "+1-6467717104",
      "#Visits": 6,
    },
    {
      "Customer Name": "Nehajeha",
      "Customer Phone#": "+1-6316939704",
      "#Visits": 6,
    },
    {
      "Customer Name": "Rohit",
      "Customer Phone#": "+1-5513589338",
      "#Visits": 6,
    },
    {
      "Customer Name": "Ravi Shekhar",
      "Customer Phone#": "+1-2019932517",
      "#Visits": 6,
    },
    {
      "Customer Name": "Sujith ",
      "Customer Phone#": "+1-5125170842",
      "#Visits": 6,
    },
  ],
};
