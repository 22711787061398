import React, { useState, useRef, useEffect, ChangeEvent } from "react";
import "./Dropdown.scss";
import UpArrow from "../../../assets/images/dropdown.png";

interface DropdownProps {
  selectedValues?: string[];
  onSelect: (values: string[]) => void;
  options?: string[];
  label: string;
  validation?: {
    isValid: boolean;
    errorMessage?: string;
  };
  onBlur?: () => void;
  isopened?: React.Dispatch<React.SetStateAction<boolean>>;
  width: string;
  index?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleValidate?: () => void;
  placeHolder?: string;
  validatedineMealType?: any;
  toggleOnorOff?: boolean;
  EnabledOrNot?: boolean;
  validatepickupdelivery?: any;
  color?:string;
  streams?:boolean;
  zIndex?:boolean
}

const Dropdown: React.FC<DropdownProps> = ({
  selectedValues = [],
  onSelect,
  options = [],
  label,
  validation,
  width,
  streams=false,
  onBlur,
  color,
  EnabledOrNot ,
  handleValidate,
  placeHolder,
  validatedineMealType,
  toggleOnorOff,
  validatepickupdelivery,
  zIndex
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [rotateImg, setRotateImg] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [touched, setTouched] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        if (isOpen) {
          setIsOpen(false);
          setRotateImg(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, selectedValues]);

  const handleDropdownClick = () => {
    if(EnabledOrNot){
      setIsOpen(!isOpen);
      setRotateImg(!rotateImg);
      setTouched(true);
     }
  
  };

  const handleOptionClick = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const newSelectedValues = selectedValues.includes(value)
      ? selectedValues.filter((item) => item !== value)
      : [...selectedValues, value];
    onSelect(newSelectedValues);

    validatedineMealType && validatedineMealType();
    validatepickupdelivery &&
      validatepickupdelivery(toggleOnorOff, newSelectedValues);
  };

  const validateDropdown = (values: string[]) => {
    if (values.length === 0 && touched) {
      onBlur && onBlur();
    }
  };

  return (
    <div className="dropdown-containerPricing" ref={dropdownRef} style={{opacity:EnabledOrNot ? "100%" : "60%"}}>
      <label className="droplabelPricing" style={{color:color?`${color}`:"#666666"}}>{label}</label>
      <div
        className="dropdownPricingList"
        style={{ width: width === "Drop1" ? "300px" : "100px" }}
        onClick={handleDropdownClick}
        tabIndex={0}
      >
        {Array.isArray(selectedValues) && selectedValues.length > 0 ? (
          <div className="valuePricing">
            {selectedValues.slice(0, 3).join(", ")}
          </div>
        ) : (
          <div className="valuePlaceholder">
            {placeHolder === "Third Party" ? "Third Party" : ""}
          </div>
        )}

        <div>
          <img
            src={UpArrow}
            className={rotateImg ? "arrowrotatePricing" : "arrowdropPricing"}
            alt="arrow"
          />
        </div>
      </div>

      {isOpen && (
        <div className={zIndex ? "optionsPricingz" : "optionsPricing"}>
          {options.length > 0 ? (
            options.map((option, index) => (
              <label key={index} style={{display:"flex",justifyContent:"left",alignItems:"center",gap:"10px",cursor:"pointer"}}>
                <input
                  type="checkbox"
                  name={option}
                  className="checkboxPricing"
                  value={option}
                  onBlur={onBlur}
                  checked={selectedValues.includes(option)}
                  onChange={handleOptionClick}
                  style={{marginBottom:streams?"0.3rem":"",}}
                />
                <p style={{marginTop:streams?"-0.5rem":"",marginBottom:streams?"0.3rem":""}}>{option}</p>
              </label>
            ))
          ) : (
            <div className="No-Option-Availble-dropdown" style={{zIndex:"2"}}>
              No options available
            </div>
          )}
        </div>
      )}

      {!validation?.isValid && (
        <p style={{ color: "red", fontSize: "0.75rem", fontWeight: "500" }}>
          {validation?.errorMessage}
        </p>
      )}
    </div>
  );
};

export default Dropdown;
