export const DDDD = {
  "PickUp/Delivery": [
    {
      "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
      "Order Time": "2024-08-20T21:02:10Z",
      "Item Name": "Aloo Paratha",
      Quantity: 3,
      "Status ID": 12,
      "Type Name": "Delivery",
      "drop down": [
        {
          "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
          "Order Time": "2024-08-20T21:02:10Z",
          "Item Name": "Aloo Paratha",
        },
        // more nested rows if necessary
      ],
    },
    // other rows if necessary
    {
      "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
      "Order Time": "2024-08-20T21:02:10Z",
      "Item Name": "Aloo Paratha",
      Quantity: 3,
      "Status ID": 12,
      "Type Name": "Delivery",
      "drop down": [
        {
          "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
          "Order Time": "2024-08-20T21:02:10Z",
          "Item Name": "Aloo Paratha",
        },
        // more nested rows if necessary
      ],
    },
    {
      "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
      "Order Time": "2024-08-20T21:02:10Z",
      "Item Name": "Aloo Paratha",
      Quantity: 3,
      "Status ID": 12,
      "Type Name": "Delivery",
      "drop down": [
        {
          "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
          "Order Time": "2024-08-20T21:02:10Z",
          "Item Name": "Aloo Paratha",
        },
        // more nested rows if necessary
      ],
    },
    {
      "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
      "Order Time": "2024-08-20T21:02:10Z",
      "Item Name": "Aloo Paratha",
      Quantity: 3,
      "Status ID": 12,
      "Type Name": "Delivery",
      "drop down": [
        {
          "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
          "Order Time": "2024-08-20T21:02:10Z",
          "Item Name": "Aloo Paratha",
        },
        // more nested rows if necessary
      ],
    },
    {
      "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
      "Order Time": "2024-08-20T21:02:10Z",
      "Item Name": "Aloo Paratha",
      Quantity: 3,
      "Status ID": 12,
      "Type Name": "Delivery",
      "drop down": [
        {
          "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
          "Order Time": "2024-08-20T21:02:10Z",
          "Item Name": "Aloo Paratha",
        },
        // more nested rows if necessary
      ],
    },
    {
      "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
      "Order Time": "2024-08-20T21:02:10Z",
      "Item Name": "Aloo Paratha",
      Quantity: 3,
      "Status ID": 12,
      "Type Name": "Delivery",
      "drop down": [
        {
          "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
          "Order Time": "2024-08-20T21:02:10Z",
          "Item Name": "Aloo Paratha",
        },
        // more nested rows if necessary
      ],
    },
    {
      "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
      "Order Time": "2024-08-20T21:02:10Z",
      "Item Name": "Aloo Paratha",
      Quantity: 3,
      "Status ID": 12,
      "Type Name": "Delivery",
      "drop down": [
        {
          "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
          "Order Time": "2024-08-20T21:02:10Z",
          "Item Name": "Aloo Paratha",
        },
        // more nested rows if necessary
      ],
    },
    {
      "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
      "Order Time": "2024-08-20T21:02:10Z",
      "Item Name": "Aloo Paratha",
      Quantity: 3,
      "Status ID": 12,
      "Type Name": "Delivery",
      "drop down": [
        {
          "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
          "Order Time": "2024-08-20T21:02:10Z",
          "Item Name": "Aloo Paratha",
        },
        // more nested rows if necessary
      ],
    },
    {
      "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
      "Order Time": "2024-08-20T21:02:10Z",
      "Item Name": "Aloo Paratha",
      Quantity: 3,
      "Status ID": 12,
      "Type Name": "Delivery",
      "drop down": [
        {
          "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
          "Order Time": "2024-08-20T21:02:10Z",
          "Item Name": "Aloo Paratha",
        },
        // more nested rows if necessary
      ],
    },
    {
      "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
      "Order Time": "2024-08-20T21:02:10Z",
      "Item Name": "Aloo Paratha",
      Quantity: 3,
      "Status ID": 12,
      "Type Name": "Delivery",
      "drop down": [
        {
          "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
          "Order Time": "2024-08-20T21:02:10Z",
          "Item Name": "Aloo Paratha",
        },
        // more nested rows if necessary
      ],
    },
    {
      "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
      "Order Time": "2024-08-20T21:02:10Z",
      "Item Name": "Aloo Paratha",
      Quantity: 3,
      "Status ID": 12,
      "Type Name": "Delivery",
      "drop down": [
        {
          "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
          "Order Time": "2024-08-20T21:02:10Z",
          "Item Name": "Aloo Paratha",
        },
        // more nested rows if necessary
      ],
    },
    {
      "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
      "Order Time": "2024-08-20T21:02:10Z",
      "Item Name": "Aloo Paratha",
      Quantity: 3,
      "Status ID": 12,
      "Type Name": "Delivery",
      "drop down": [
        {
          "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
          "Order Time": "2024-08-20T21:02:10Z",
          "Item Name": "Aloo Paratha",
        },
        // more nested rows if necessary
      ],
    },
    {
      "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
      "Order Time": "2024-08-20T21:02:10Z",
      "Item Name": "Aloo Paratha",
      Quantity: 3,
      "Status ID": 12,
      "Type Name": "Delivery",
      "drop down": [
        {
          "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
          "Order Time": "2024-08-20T21:02:10Z",
          "Item Name": "Aloo Paratha",
        },
        // more nested rows if necessary
      ],
    },
    {
      "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
      "Order Time": "2024-08-20T21:02:10Z",
      "Item Name": "Aloo Paratha",
      Quantity: 3,
      "Status ID": 12,
      "Type Name": "Delivery",
      "drop down": [
        {
          "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
          "Order Time": "2024-08-20T21:02:10Z",
          "Item Name": "Aloo Paratha",
        },
        // more nested rows if necessary
      ],
    },
    {
      "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
      "Order Time": "2024-08-20T21:02:10Z",
      "Item Name": "Aloo Paratha",
      Quantity: 3,
      "Status ID": 12,
      "Type Name": "Delivery",
      "drop down": [
        {
          "Order ID": "00772033-9ac0-4a0c-8f49-a936a0de17c4",
          "Order Time": "2024-08-20T21:02:10Z",
          "Item Name": "Aloo Paratha",
        },
        // more nested rows if necessary
      ],
    },
  ],
  Items: [
    "All Items",
    "Varieties",
    "Specials",
    "Rice",
    "Noodles",
    "Dishes",
    "North Indian",
    "South Indian",
    "Andhra",
    "Vegetarian",
    "Non-Vegetarian",
    "Steamed",
    "Breakfast",
    "Lunch",
    "Items",
    "Chutneys",
    "Pickles",
    "Specialties",
    "Curries",
    "Indian",
    "Western",
    "Hot",
    "Cold",
  ],
};
