export const custIn = {
  "Customer order details": [
    {
      "Customer Name": "Vikanya",
      "Phone Number": "424363963242342346516006157",
      "Order Date": "2024-08-22T20:49:32-04:00",
      "Order Number": "002432",
      "Total Items": 4,
      "Order Total": 56.5,
      "Order Type": "Pickup (Online)",
      "Items Ordered":
        "Chapati Kurma (Qty-1) \nImpossible Veg Fried Rice (Qty-1) \nSweet Corn Veg Soup (Qty-1) \nVegetable Cutlet (Qty-1) ",
      "Order URL":
        "https://app.magilhub.com/restaurant/a2b-parsippany/order/7eeb6253-e3c1-4613-ae3f-19d965a131a0",
    },
    {
      "Customer Name": "Mesfin Bedada",
      "Phone Number": "424363963242342342406047343",
      "Order Date": "2024-08-21T20:28:12-04:00",
      "Order Number": "002431",
      "Total Items": 1,
      "Order Total": 19.44,
      "Order Type": "Pickup (Online)",
      "Items Ordered": "Paneer Masala Dosai (Qty-1) ",
      "Order URL":
        "https://app.magilhub.com/restaurant/a2b-parsippany/order/d4423e0d-5c00-4f72-926e-2521ff081859",
    },
    {
      "Customer Name": "Shubha",
      "Phone Number": "424363963242342349737752459",
      "Order Date": "2024-08-20T18:07:11-04:00",
      "Order Number": "002430",
      "Total Items": 2,
      "Order Total": 22.37,
      "Order Type": "Pickup (Online)",
      "Items Ordered": "Kashmiri Naan (Qty-1) \nMethi Malai Matar (Qty-1) ",
      "Order URL":
        "https://app.magilhub.com/restaurant/a2b-parsippany/order/391cc620-4ad4-4f8c-9507-c2afd0309d89",
    },
    {
      "Customer Name": "Anish Ananthasamy",
      "Phone Number": "424363963242342344752438617",
      "Order Date": "2024-08-20T11:58:27-04:00",
      "Order Number": "002429",
      "Total Items": 1,
      "Order Total": 20.25,
      "Order Type": "Pickup (Online)",
      "Items Ordered": "South Indian Thali (Qty-1) ",
      "Order URL":
        "https://app.magilhub.com/restaurant/a2b-parsippany/order/f280d957-70f6-47d3-ad6e-910ce5fb1f02",
    },
    {
      "Customer Name": "Mandeep Desai",
      "Phone Number": "424363963242342345712645949",
      "Order Date": "2024-08-18T18:43:56-04:00",
      "Order Number": "002428",
      "Total Items": 5,
      "Order Total": 82.55,
      "Order Type": "Pickup (Online)",
      "Items Ordered":
        "Chilli Garlic Naan (Qty-3) \nDal Tadka (Qty-1) \nPaneer Butter Masala (Qty-2) \nRoti (Qty-1) \nVegetable Jalfrezi (Qty-1) ",
      "Order URL":
        "https://app.magilhub.com/restaurant/a2b-parsippany/order/484d4bd4-bdca-46d8-b98f-4367a8918853",
    },
    {
      "Customer Name": "Bhaskar",
      "Phone Number": "424363963242342342673072640",
      "Order Date": "2024-08-18T18:26:19-04:00",
      "Order Number": "002427",
      "Total Items": 6,
      "Order Total": 80.98,
      "Order Type": "Pickup (Online)",
      "Items Ordered":
        "Bisi Bele Bath (Qty-1) \nIdli Vadai (Qty-1) \nMango Lassi (Qty-1) \nMini Tiffin (Qty-1) \nPlain Dosai (Qty-1) \nSambar Thatte Idly (Qty-1) ",
      "Order URL":
        "https://app.magilhub.com/restaurant/a2b-parsippany/order/a5cdd53e-eac9-4010-a92d-e27ad77bcebc",
    },
    {
      "Customer Name": "mayank chourasia",
      "Phone Number": "424363963242342349089185460",
      "Order Date": "2024-08-18T18:19:45-04:00",
      "Order Number": "002426",
      "Total Items": 3,
      "Order Total": 49.55,
      "Order Type": "Pickup (Online)",
      "Items Ordered":
        "Babycorn Chilli Fries (Qty-1) \nSzechwan Noodles (Qty-1) \nVegetable Dum Biriyani (Qty-1) ",
      "Order URL":
        "https://app.magilhub.com/restaurant/a2b-parsippany/order/bd66fede-ce27-4424-ad79-8bc2ffb66c9a",
    },
    {
      "Customer Name": "Anish Ananthasamy",
      "Phone Number": "424363963242342344752438617",
      "Order Date": "2024-08-18T14:15:27-04:00",
      "Order Number": "002424",
      "Total Items": 1,
      "Order Total": 20.25,
      "Order Type": "Pickup (Online)",
      "Items Ordered": "South Indian Thali (Qty-1) ",
      "Order URL":
        "https://app.magilhub.com/restaurant/a2b-parsippany/order/a5c01470-ff7f-42a2-960a-4691d3c50747",
    },
    {
      "Customer Name": "Sudhir D",
      "Phone Number": "424363963242342342018875989",
      "Order Date": "2024-08-18T12:02:14-04:00",
      "Order Number": "002423",
      "Total Items": 3,
      "Order Total": 44.75,
      "Order Type": "Pickup (Online)",
      "Items Ordered":
        "Ghee Pongal (Qty-1) \nPaneer Pepper Fries (Qty-1) \nVegetable Noodles (Qty-1) ",
      "Order URL":
        "https://app.magilhub.com/restaurant/a2b-parsippany/order/2861319a-4a8d-40ee-b916-011b218d6e14",
    },
    {
      "Customer Name": "Jyothi Kulkarni",
      "Phone Number": "424363963242342342014142595",
      "Order Date": "2024-08-18T11:53:10-04:00",
      "Order Number": "002422",
      "Total Items": 4,
      "Order Total": 65.16,
      "Order Type": "Pickup (Online)",
      "Items Ordered":
        "Chole Bhature (Qty-1) \nDrumstick Soup (Qty-1) \nMatar Mushroom (Qty-1) \nPoori Masala (Qty-1) ",
      "Order URL":
        "https://app.magilhub.com/restaurant/a2b-parsippany/order/46b3c309-fd7e-404f-a19f-c6aced93df01",
    },
    {
      "Customer Name": "Bharathi Sadiappan",
      "Phone Number": "424363963242342345713327182",
      "Order Date": "2024-08-17T12:59:17-04:00",
      "Order Number": "002420",
      "Total Items": 4,
      "Order Total": 92.73,
      "Order Type": "Pickup (Online)",
      "Items Ordered":
        "Babycorn Pepper Fries (Qty-1) \nDrumstick Soup (Qty-1) \nHot & Sour Veg Soup (Qty-1) \nSouth Indian Thali (Qty-3) ",
      "Order URL":
        "https://app.magilhub.com/restaurant/a2b-parsippany/order/ddb84a2c-b979-40a0-9575-b9367346ebd5",
    },
    {
      "Customer Name": "Jayanthi Anandan",
      "Phone Number": "424363963242342342019899273",
      "Order Date": "2024-08-17T12:30:56-04:00",
      "Order Number": "002419",
      "Total Items": 5,
      "Order Total": 66.41,
      "Order Type": "Pickup (Online)",
      "Items Ordered":
        "Curd Rice (Qty-1) \nPoori Masala (Qty-1) \nPotato Bajji (Qty-1) \nRose Milk (Qty-1) \nSouth Indian Rush Lunch(Lunch Only) (Qty-1) ",
      "Order URL":
        "https://app.magilhub.com/restaurant/a2b-parsippany/order/afb9a0f4-6097-4164-8171-22e40bd6c96f",
    },
    {
      "Customer Name": "Abhilash",
      "Phone Number": "424363963242342346314282100",
      "Order Date": "2024-08-16T17:37:00-04:00",
      "Order Number": "002416",
      "Total Items": 2,
      "Order Total": 28.76,
      "Order Type": "Pickup (Online)",
      "Items Ordered":
        "Idiyappam (Dinner only) (Qty-1) \nSambar Thatte Idly (Qty-1) ",
      "Order URL":
        "https://app.magilhub.com/restaurant/a2b-parsippany/order/66a15232-142b-407b-9f8f-5827c73c8a16",
    },
  ],
  "DineIn Insights": [
    {
      "Customer Name": "Ganesh Ekambaram",
      "Phone Number": "4575474535235358482280607",
      "Dinein Tenure (in months)": 10,
      "Dinein Customer Time": "2024-05-16T00:00:00-04:00",
      "Group Type": "New",
      "First Visit": "2024-05-16T00:00:00-04:00",
      "Latest Visit": "2024-05-16T00:00:00-04:00",
      "Total Visits": 1,
      "Avg Group Size": 271,
    },
    {
      "Customer Name": "Vikanya",
      "Phone Number": "4575474535235356516006157",
      "Dinein Tenure (in months)": 17,
      "Dinein Customer Time": "2024-08-06T00:00:00-04:00",
      "Group Type": "Repeat",
      "First Visit": "2024-08-06T00:00:00-04:00",
      "Latest Visit": "2024-08-22T00:00:00-04:00",
      "Total Visits": 5,
      "Avg Group Size": 50,
    },
    {
      "Customer Name": "Anshu Tomar",
      "Phone Number": "4575474535235352016680991",
      "Dinein Tenure (in months)": 17,
      "Dinein Customer Time": "2024-05-25T00:00:00-04:00",
      "Group Type": "Repeat",
      "First Visit": "2024-05-25T00:00:00-04:00",
      "Latest Visit": "2024-07-26T00:00:00-04:00",
      "Total Visits": 4,
      "Avg Group Size": 62,
    },
    {
      "Customer Name": "Vignesh Chand",
      "Phone Number": "4575474535235357187220390",
      "Dinein Tenure (in months)": 16,
      "Dinein Customer Time": "2024-05-08T00:00:00-04:00",
      "Group Type": "Repeat",
      "First Visit": "2024-05-08T00:00:00-04:00",
      "Latest Visit": "2024-07-24T00:00:00-04:00",
      "Total Visits": 4,
      "Avg Group Size": 53,
    },
    {
      "Customer Name": "Sumedh",
      "Phone Number": "4575474535235354125805834",
      "Dinein Tenure (in months)": 10,
      "Dinein Customer Time": "2024-06-29T00:00:00-04:00",
      "Group Type": "Repeat",
      "First Visit": "2024-06-29T00:00:00-04:00",
      "Latest Visit": "2024-07-02T00:00:00-04:00",
      "Total Visits": 2,
      "Avg Group Size": 98,
    },
    {
      "Customer Name": "Vipen ",
      "Phone Number": "4575474535235359084329220",
      "Dinein Tenure (in months)": 13,
      "Dinein Customer Time": "2024-05-09T00:00:00-04:00",
      "Group Type": "Repeat",
      "First Visit": "2024-05-09T00:00:00-04:00",
      "Latest Visit": "2024-07-27T00:00:00-04:00",
      "Total Visits": 4,
      "Avg Group Size": 49,
    },
    {
      "Customer Name": "Kamal Sharma ",
      "Phone Number": "4575474535235359736153206",
      "Dinein Tenure (in months)": 10,
      "Dinein Customer Time": "2024-05-22T00:00:00-04:00",
      "Group Type": "Repeat",
      "First Visit": "2024-05-22T00:00:00-04:00",
      "Latest Visit": "2024-07-20T00:00:00-04:00",
      "Total Visits": 2,
      "Avg Group Size": 80,
    },
    {
      "Customer Name": "Abraham Mohan",
      "Phone Number": "4575474535235357134496527",
      "Dinein Tenure (in months)": 9,
      "Dinein Customer Time": "2024-05-24T00:00:00-04:00",
      "Group Type": "Repeat",
      "First Visit": "2024-05-24T00:00:00-04:00",
      "Latest Visit": "2024-07-12T00:00:00-04:00",
      "Total Visits": 3,
      "Avg Group Size": 52,
    },
    {
      "Customer Name": "Omkar Munipalle",
      "Phone Number": "4575474535235354088568404",
      "Dinein Tenure (in months)": 10,
      "Dinein Customer Time": "2024-06-24T00:00:00-04:00",
      "Group Type": "New",
      "First Visit": "2024-06-24T00:00:00-04:00",
      "Latest Visit": "2024-06-24T00:00:00-04:00",
      "Total Visits": 1,
      "Avg Group Size": 156,
    },
    {
      "Customer Name": "Sairam Thoutreddy",
      "Phone Number": "4575474535235356466039504",
      "Dinein Tenure (in months)": 5,
      "Dinein Customer Time": "2024-05-11T00:00:00-04:00",
      "Group Type": "Repeat",
      "First Visit": "2024-05-11T00:00:00-04:00",
      "Latest Visit": "2024-06-15T00:00:00-04:00",
      "Total Visits": 2,
      "Avg Group Size": 77,
    },
  ],
};
