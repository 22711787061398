// Get Menu Category
export const GET_MENU_CATEGORY_REQUEST = "GET_MENU_CATEGORY_REQUEST";
export const GET_MENU_CATEGORY_SUCCESS = "GET_MENU_CATEGORY_SUCCESS";
export const GET_MENU_CATEGORY_FAILED = "GET_MENU_CATEGORY_FAILED";

// Get menu Sub Category
export const GET_MENU_SUB_CATEGORY_REQUEST = "GET_MENU_SUB_CATEGORY_REQUEST";
export const GET_MENU_SUB_CATEGORY_SUCCESS = "GET_MENU_SUB_CATEGORY_SUCCESS";
export const GET_MENU_SUB_CATEGORY_FAILED = "GET_MENU_SUB_CATEGORY_FAILED";

// Get TAG Class
export const GET_TAG_CLASS_REQUEST = "GET_TAG_CLASS_REQUEST";
export const GET_TAG_CLASS_SUCCESS = "GET_TAG_CLASS_SUCCESS";
export const GET_TAG_CLASS_FAILED = "GET_TAG_CLASS_FAILED";

// Get Availability Class
export const GET_AVAILABILITY_REQUEST = "GET_AVAILABILITY_REQUEST";
export const GET_AVAILABILITY_SUCCESS = "GET_AVAILABILITY_SUCCESS";
export const GET_AVAILABILITY_FAILED = "GET_AVAILABILITY_FAILED";

// Get Ingredients
export const GET_INGR_REQUEST = "GET_INGR_REQUEST";
export const GET_INGR_SUCCESS = "GET_INGR_SUCCESS";
export const GET_INGR_FAILED = "GET_INGR_FAILED";

// Get Modifiers
export const GET_MODIFIER_REQUEST = "GET_MODIFIER_REQUEST";
export const GET_MODIFIER_SUCCESS = "GET_MODIFIER_SUCCESS";
export const GET_MODIFIER_FAILED = "GET_MODIFIER_FAILED";

// Add Menu Items
export const ADD_MENU_ITEM_REQUEST = "ADD_MENU_ITEM_REQUEST";
export const ADD_MENU_ITEM_SUCCESS = "ADD_MENU_ITEM_SUCCESS";
export const ADD_MENU_ITEM_FAILED = "ADD_MENU_ITEM_FAILED";
export const CLEAR_MENU_ITEM_SUCCESS = "CLEAR_MENU_ITEM_SUCCESS";

// Update Menu Item
export const UPDATE_MENU_ITEM_REQUEST = "UPDATE_MENU_ITEM_REQUEST";
export const UPDATE_MENU_ITEM_SUCCESS = "UPDATE_MENU_ITEM_SUCCESS";
export const UPDATE_MENU_ITEM_FAILED = "UPDATE_MENU_ITEM_FAILED";
export const CLEAR_MENU_ITEM_MSG = "CLEAR_MENU_ITEM_MSG";

// Delete Menu Item
export const DELETE_MENU_ITEM_REQUEST = "DELETE_MENU_ITEM_REQUEST";
export const DELETE_MENU_ITEM_SUCCESS = "DELETE_MENU_ITEM_SUCCESS";
export const DELETE_MENU_ITEM_FAILED = "DELETE_MENU_ITEM_FAILED";
export const RESET_DELETE_DATA = "RESET_DELETE_DATA";

// update menu item Attributes
export const UPDATE_MENU_ATTRIBUTE_REQUEST = "UPDATE_MENU_ATTRIBUTE_REQUEST";
export const UPDATE_MENU_ATTRIBUTE_SUCCESS = "UPDATE_MENU_ATTRIBUTE_SUCCESS";
export const UPDATE_MENU_ATTRIBUTE_FAILED = "UPDATE_MENU_ATTRIBUTE_FAILED";

// Primary Page Reducer Attributes

export const Primary_Post_Data_Send = "Primary_Post_Data_Send";
export const Primary_Post_Data_Success = "Primary_Post_Data_Success";
export const Primary_Post_Data_Failure = "Primary_Post_Data_Failure";
export const Remove_Primary_Data_Request = "Remove_Primary_Data_Request"

// Item Customization  Reducer Attributes

export const Item_Customizations_Data_Request = "Item_Customizations_Data_Request";
export const Item_Customizations_Data_Success = "Item_Customizations_Data_Success";
export const Item_Customizations_Data_Failure = "Item_Customizations_Data_Failure";
export const Remove_ItemCust_Data_Request = "Remove_ItemCust_Data_Request";
// Prizing Detail   Reducer Attributes

export const Pricing_Detail_Data_Request = "Pricing_Detail_Data_Request";
export const Pricing_Detail_Data_Success = "Pricing_Detail_Data_Success";
export const Pricing_Detail_Data_Failure = "Pricing_Detail_Data_Failure";
export const Remove_Pricing_Data_Request = "Remove_Pricing_Data_Request"
export const Get_ItemImage = "Get_Image";
export const Get_ItemImage_Success = "Get_Image";
export const Get_ItemImage_Failure = "Get_Image";

// Action Types
export const STORE_MOCK_DATA_REQUEST = "STORE_MOCK_DATA_REQUEST";
export const STORE_MOCK_DATA_SUCCESS = "STORE_MOCK_DATA_SUCCESS";
export const STORE_MOCK_DATA_FAILURE = "STORE_MOCK_DATA_FAILURE";

export const STORE_MENU_REQUEST = "STORE_MENU_REQUEST";
export const STORE_MENU_SUCCESS = "STORE_MENU_SUCCESS";
export const STORE_MENU_FAILURE = "STORE_MENU_FAILURE";

export const STORE_MOCK_DATA_FILTERED_REQUEST = "STORE_MOCK_DATA_FILTERED_REQUEST";
export const STORE_MOCK_DATA_FILTERED_SUCCESS = "STORE_MOCK_DATA_FILTERED_SUCCESS";
export const STORE_MOCK_DATA_FILTERED_FAILURE = "STORE_MOCK_DATA_FILTERED_FAILURE";

export const ADD_MOCK_DATA_REQUEST = " ADD_MOCK_DATA_REQUEST";
export const ADD_MOCK_DATA_SUCCESS = "ADD_MOCK_DATA_SUCCESS";
export const ADD_MOCK_DATA_FALIURE = " ADD_MOCK_DATA_FALIURE";

export const DIET_DROPDOWN_LIST_REQUEST = "DIET_DROPDOWN_LIST_REQUEST";
export const DIET_DROPDOWN_LIST_SUCCESS = "DIET_DROPDOWN_LIST_SUCCESS";
export const DIET_DROPDOWN_LIST_FAILURE = "DIET_DROPDOWN_LIST_FAILURE";

export const DIET_DATA_REQUEST = "DIET_DATA_REQUEST";
export const DIET_DATA_SUCCESS = "DIET_DATA_SUCCESS";
export const DIET_DATA_FAILURE = "DIET_DATA_FAILURE";

export const CUISINE_DATA_REQUEST = "CUISINE_DATA_REQUEST";
export const CUISINE_DATA_SUCCESS = "CUISINE_DATA_SUCCESS";
export const CUISINE_DATA_FAILURE = "CUISINE_DATA_FAILURE";

export const CATEGORY_DATA_REQUEST = "CATEGORY_DATA_REQUEST";
export const CATEGORY_DATA_SUCCESS = "CATEGORY_DATA_SUCCESS";
export const CATEGORY_DATA_FAILURE = "CATEGORY_DATA_FAILURE";

export const BESTPAIR_DATA_REQUEST = "BESTPAIR_DATA_REQUEST";
export const BESTPAIR_DATA_SUCCESS = "BESTPAIR_DATA_SUCCESS";
export const BESTPAIR_DATA_FAILURE = "BESTPAIR_DATA_FAILURE";

export const SUBCATEGORY_DATA_REQUEST = "SUBCATEGORY_DATA_REQUEST";
export const SUBCATEGORY_DATA_SUCCESS = "SUBCATEGORY_DATA_SUCCESS";
export const SUBCATEGORY_DATA_FAILURE = "SUBCATEGORY_DATA_FAILURE";

export const ALLERGENS_REQUEST = 'ALLERGENS_REQUEST';
export const ALLERGENS_SUCCESS = 'ALLERGENS_SUCCESS';
export const ALLERGENS_FAILURE = 'ALLERGENS_FAILURE';

export const INGREDIENTS_REQUEST = 'INGREDIENTS_REQUEST';
export const INGREDIENT_SUCESS = 'INGREDIENT_SUCESS';
export const INGREDIENT_FAILURE = 'INGREDIENT_FAILURE';

export const KITCHEN_DATA_REQUEST = "KITCHEN_DATA_REQUEST";
export const KITCHEN_DATA_SUCCESS = "KITCHEN_DATA_SUCCESS";
export const KITCHEN_DATA_FAILURE = "KITCHEN_DATA_FAILURE";

export const TAXCLASS_REQUEST = "TAXCLASS_REQUEST";
export const TAXCLASS_SUCCESS = "TAXCLASS_SUCCESS";
export const TAXCLASS_FAILURE = "TAXCLASS_FAILURE";

export const FETCHDROPDOWN_REQUEST = "FETCHDROPDOWN_REQUEST";
export const FETCHDROPDOWN_SUCCESS = "FETCHDROPDOWN_SUCCESS";
export const FETCHDROPDOWN_FAILURE = "FETCHDROPDOWN_FAILURE";

//Delete
export const DELETEDIETARY_REQUEST = "DELETEDIETARY_REQUEST";
export const DELETEDIETARY_SUCCESS = "DELETEDIETARY_SUCCESS";
export const DELETEDIETARY_FAILURE = "DELETEDIETARY_FAILURE";

export const DELETECUISINE_REQUEST = "DELETECUISINE_REQUEST";
export const DELETECUISINE_SUCCESS = "DELETECUISINE_SUCCESS";
export const DELETECUISINE_FAILURE = "DELETECUISINE_FAILURE";

export const DELETECATEGORY_REQUEST = "DELETECATEGORY_REQUEST";
export const DELETECATEGORY_SUCCESS = "DELETECATEGORY_SUCCESS";
export const DELETECATEGORY_FAILURE = "DELETECATEGORY_FAILURE";

export const DELETESUBCATEGORY_REQUEST = "DELETESUBCATEGORY_REQUEST";
export const DELETESUBCATEGORY_SUCCESS = "DELETESUBCATEGORY_SUCCESS";
export const DELETESUBCATEGORY_FAILURE = "DELETESUBCATEGORY_FAILURE";

//Delete Subsection

export const DELETEDROPDOWN_REQUEST = "DELETEDROPDOWN_REQUEST";
export const DELETEDROPDOWN_SUCCESS = "DELETEDROPDOWN_SUCCESS";
export const DELETEDROPDOWN_FAILURE = "DELETEDROPDOWN_FAILURE";

//Add Subsection
export const ADDDROPDOWN_REQUEST = "ADDDROPDOWN_REQUEST";
export const ADDDROPDOWN_SUCCESS = "ADDDROPDOWN_SUCCESS";
export const ADDDROPDOWN_FAILURE = "ADDDROPDOWN_FAILURE";

//Image Upload
export const UPLOAD_IMAGE_IN_PROGRESS = "UPLOAD_IMAGE_IN_PROGRESS";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE";

export const ADD_MOCK_DATA_HIDDEN_REQUEST = " ADD_MOCK_DATA_HIDDEN_REQUEST";
export const ADD_MOCK_DATA_HIDDEN_SUCCESS = "ADD_MOCK_DATA_HIDDEN_SUCCESS";
export const ADD_MOCK_DATA_HIDDEN_FALIURE = " ADD_MOCK_DATA_HIDDEN_FALIURE";

export const GET_ITEM_CODE_REQUEST = "GET_ITEM_CODE_REQUEST";
export const GET_ITEM_CODE_SUCCESS = "GET_ITEM_CODE_SUCCESS";
export const GET_ITEM_CODE_FAILURE = "GET_ITEM_CODE_FAILURE";

export const GET_POPULAR_ITEM_REQUEST = "GET_POPULAR_ITEM_REQUEST";
export const GET_POPULAR_ITEM_SUCCESS = "GET_POPULAR_ITEM_SUCCESS";
export const GET_POPULAR_ITEM_FAILURE = "GET_POPULAR_ITEM_FAILURE";

// ***********************SelectedMockData*******************************

export const SELECTED_MOCKDATA_REQUEST = "SELECTED_MOCKDATA_REQUEST";
export const SELECTED_MOCKDATA_SUCCESS = "SELECTED_MOCKDATA_SUCCESS";
export const SELECTED_MOCKDATA_FAILURE = "SELECTED_MOCKDATA_FAILURE";

// **********************PartialUpdate***************************

export const PARTIAL_UPDATE_MENU_REQUEST = "PARTIAL_UPDATE_MENU_REQUEST";
export const PARTIAL_UPDATE_MENU_SUCCESS = "PARTIAL_UPDATE_MENU_SUCCESS";
export const PARTIAL_UPDATE_MENU_FAILURE = "PARTIAL_UPDATE_MENU_FAILURE";

export const START_IMAGE_UPLOAD = "START_IMAGE_UPLOAD";
export const IMAGE_UPLOAD_SUCCESS = "IMAGE_UPLOAD_SUCCESS";
export const IMAGE_UPLOAD_FAILURE = "IMAGE_UPLOAD_FAILURE";
export const STORE_UPLOAD_FAILURE = "STORE_UPLOAD_FAILURE";
export const STORE_UPLOAD_SUCCESS = "STORE_UPLOAD_SUCCESS";
export const API_UPLOAD_FAILURE = "STORE_UPLOAD_FAILURE";
export const RETRY_IMAGE_UPLOAD = "RETRY_IMAGE_UPLOAD";
export const RETRY_IMAGE_SUCCESS = "RETRY_IMAGE_SUCCESS";
export const RETRY_IMAGE_FAILURE = "RETRY_IMAGE_FAILURE";

//Search Item
export const SEARCH_FORITEM="SEARCH_FORITEM";
export const SEARCH_FORITEM_SUCCESS="SEARCH_FORITEM_SUCCESS";
export const SEARCH_FORITEM_FAILURE="SEARCH_FORITEM_FAILURE";

export const SELECTED_CATEGORY_DATA_REQUEST=" SELECTED_CATEGORY_DATA_REQUEST"

// {*******************StoreItemWithCatagoryID***************************}

export const STORE_DATA_WITH_CATEGORY = 'STORE_DATA_WITH_CATEGORY';
export const STORE_DATA_WITH_CATEGORY_SUCCESS = 'STORE_DATA_WITH_CATEGORY_SUCCESS';
export const STORE_DATA_WITH_CATEGORY_FAILURE = 'STORE_DATA_WITH_CATEGORY_FAILURE';

export const DELETE_MODIFIER_REQUEST = "DELETE_MODIFIER_REQUEST";
export const DELETE_MODIFIER_SUCCESS = "DELETE_MODIFIER_SUCCESS";
export const DELETE_MODIFIER_FAILURE = "DELETE_MODIFIER_FAILURE";

export const UPDATE_MODIFIER_REQUEST = 'UPDATE_MODIFIER_REQUEST';

export const REMOVE_DATA_REQUEST = 'REMOVE_DATA_REQUEST';
export const REMOVE_CODE_REQUEST ='REMOVE_CODE_REQUEST'
export const REMOVE_DATA_SUCCESS = 'REMOVE_DATA_SUCCESS';
export const REMOVE_DATA_FAILURE = 'REMOVE_DATA_FAILURE';
export const REMOVE_CODE_SUCCESS = 'REMOVE_DATA_SUCCESS';
export const REMOVE_CODE_FAILURE = 'REMOVE_DATA_FAILURE';
export const RESET_SUCCESS_MESSAGE="RESET_SUCCESS_MESSAGE"

//Edit image(delete)

export const DELETE_IMAGE_REQUEST = 'DELETE_IMAGE_REQUEST';
export const DELETE_IMAGE_SUCCESS = 'DELETE_IMAGE_SUCCESS';
export const DELETE_IMAGE_FAILURE="DELETE_IMAGE_FAILURE"





export const GET_ITEM_CODE_VaLIDATION_ERROR = "GET_ITEM_CODE_VaLIDATION_ERROR";
export const SELECTED_COLUMNS = "SELECTED_COLUMNS";


export const UPDATE_TRIGGER_FCM="UPDATE_TRIGGER_FCM";
export const TRIGGER_FCM="TRIGGER_FCM";
export const TRIGGERED_FCM="TRIGGERED_FCM"

export const UPDATE_SCHEDULE_TRIGGER_FCM = "UPDATE_SCHEDULE_TRIGGER_FCM"
export const SCHEDULE_FCM = "SCHEDULE_FCM"
export const SCHEDULE_FCM_RESPONSE = "SCHEDULE_FCM_RESPONSE"
