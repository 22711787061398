import React, { useEffect, useState } from 'react'
import { useHistory , useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { deleteEmployee, employeeStatusRequest, getEmployeeByIdRequest, getEmployeeRoleByIdRequest, setEditEmployeeData } from '../../redux/employee/employeeActions'
import './style/employeeDetails.css'
import menu from '../../assets/svg/menu.svg'
import x from '../../assets/svg/x.svg'
import edit from '../../assets/svg/edit.svg'
import block from '../../assets/svg/blockED.svg' 
import unBlockImg from '../../assets/svg/unBlockED.svg'
import trash from '../../assets/svg/trash.svg'
import Modal from '../../components/Modal/Modal';
import thunder from '../../assets/svg/RedThunder.svg'
import { IoIosArrowBack } from "react-icons/io";
import { showErrorToast, showInfoToast, showWarningToast } from '../../util/toastUtils';
import deleteIcon from '../../assets/svg/trash2.svg'
import blockIcon from '../../assets/svg/x-octagon.svg'
import activeIcon from '../../assets/svg/activeIcon.svg'
import { RootState } from 'redux/rootReducer';
import { EmployeeIdByDetails, RolesAndFunctions } from 'interface/employeeInterface';
import SidePanel from 'pages/SidePanel';


const EmployeeDetails = () => {
    const [showDropDown, setShowDropDown] = useState(false)
    const [date, setDate] = useState("")
    const [openDeleteModal, setDeleteOpenModal] = useState(false)
    const [openStausModal, setOpenStausModal] = useState(false)
    const [isBlocking, setIsBlocking] = useState(true)
    const [employeeToUpdate, setEmployeeToUpdate] = useState(null)
    const [isPinVisible, setIsPinVisible] = useState(false)
    const credentials = useSelector((state:RootState) => state.auth.credentials)
    const employeeDeleted = useSelector((state:RootState) => state.employee.employeeDeleted)
    const deleteEmployeeLoading = useSelector((state:RootState) => state.employee.deleteEmployeeLoading)
    const [permissionErrorMessage, setPermissionErrorMessage] = useState('')
    const [menuDisable, setmenuDisable] = useState(false)
    const [permissionStatusCode, setPermissionStatusCode] = useState<number|''>('')
    const [redirectOnClick, setRedirectOnClick] = useState(false)

    interface RouteParams {
        id?: string; 
    }

    const history = useHistory()
    const params = useParams<RouteParams>()

    useEffect(() => {
        params?.id && dispatch(getEmployeeByIdRequest({staffId:params.id,sagaCallBack:invokePermission}));
    },[params?.id])

    const invokePermission =(employeeData:any, statusCode:number)=>{
        setPermissionStatusCode(statusCode)
        employeeData?.isActive &&  dispatch(getEmployeeRoleByIdRequest({staffId:employeeData.staffId}));
        if(statusCode == 403){
            setPermissionErrorMessage(`You don't have permission`) 
        }
        statusCode !== 403 && !employeeData?.isActive && setPermissionErrorMessage('Unblock the user to view the Roles and Functions')
    }

    const employee:EmployeeIdByDetails = useSelector((state:RootState) => state.employee.employeeByIdDetails)
    const employeeByIdDetailsLoading = useSelector((state:RootState) => state.employee.employeeByIdDetailsLoading)
    const restaurantDetails = useSelector((state:RootState) => state.auth.restaurantDetails)
    const roleFunctionFetching = useSelector((state:RootState) => state.employee.roleFunctionFetching)
    const rolesAndFunctions = useSelector((state:RootState) => state.employee.rolesAndFunctions)
    const modelApiLoading  = useSelector((state:RootState) => state.employee.modelApiLoading)
    const actionApiSuccess  = useSelector((state:RootState) => state.employee.actionApiSuccess)
    const employeeStatusLoading = useSelector((state:RootState) => state.employee.employeeStatusLoading)
    const [countryCode,setCountryCode] = useState("");

    useEffect(() => {
        const countryC = restaurantDetails?.country;
        if (restaurantDetails) {
            countryC && setCountryCode(countryC === "US" ? '+1 ' : "+91 ");
        }     
    }, [restaurantDetails])
  
    const dispatch = useDispatch()
    const handleDelete = () => {
        dispatch(deleteEmployee(employee?.staffId))
    }

    useEffect(()=>{
        if(!modelApiLoading && actionApiSuccess && employeeDeleted){
            dispatch({ type: 'RESET_REMOVE_EMPLOYEE_DATA' });
            history.push("/employees")
        }
        if(actionApiSuccess && !modelApiLoading && redirectOnClick ){
            dispatch({ type: 'RESET_REMOVE_EMPLOYEE_DATA' });
            setRedirectOnClick(false)
            history.push("/employees")
        } 
    },[employeeDeleted, redirectOnClick, actionApiSuccess, modelApiLoading])


    const formatDate = (dateString:Date) => {
        if (dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString();
        }
        return '';
    }

    const fomatLocation = (location:string) => {
        const outlet =  location?.split(',')[1]
        return outlet
    }

    const formatPhoneNumber=(number:string, countryCode:string)=> {
        let phoneNumber = number.toString();
        phoneNumber = phoneNumber.replace(/\D/g, '');
        if (phoneNumber.length !== 10) {
            return '-';
        }
        return countryCode+' '+phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
    

    const isEmptyOrSpaces = (str:string) => {
        return str === null || str.match(/^ *$/) !== null;
    };
    
    const handleBtnClick = () => {
        dispatch(employeeStatusRequest(employee?.staffId, employee?.isActive));
        setEmployeeToUpdate(null);
        setShowDropDown(!showDropDown) 
        setRedirectOnClick(true)
    };
  
    useEffect(() => {
      if (actionApiSuccess && !modelApiLoading) {
        setDeleteOpenModal(false);
      }
      if(!employeeStatusLoading && actionApiSuccess && !modelApiLoading){
        setOpenStausModal(false);
        dispatch(getEmployeeByIdRequest({staffId:params.id,sagaCallBack:invokePermission}));
      }
    }, [modelApiLoading, actionApiSuccess,employeeStatusLoading])
    
    const handleNoClick = () => {
        setOpenStausModal(prev => !prev);
        setEmployeeToUpdate(null);  
        setIsBlocking(false);  
        setShowDropDown(!showDropDown)
    };

    const parseDate = (dateString?: string): Date | undefined => {
        return dateString ? new Date(dateString) : undefined;
      };

    if(employeeByIdDetailsLoading){
    return(
       <div style={{display:'flex', flexDirection:'row'}}>
         <SidePanel />
         <p style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "25%",
            marginLeft:'35%'
            }}>
            Loading, Please wait!!
        </p>
       </div>
    )
    }   

    return (
        <div style={{display:'flex', flexDirection:'row'}}>
            <SidePanel />
            <div className='employeeDetails'>
            <div className='headLine' 
                style={{
                 position: 'fixed',
                 marginTop: '-5px', 
                 marginBottom:'40px', 
                 width: '70%', 
                 zIndex: '1000', 
                 backgroundColor: '#fff', 
                 height:'100px',
                 fontSize: '24px',
                lineHeight: '36px'
                }}>
                <h3 style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '10px',
                    cursor: 'pointer',
                    fontWeight:'400',
                    fontSize:'24px'
                }}
                >
                    <IoIosArrowBack onClick={() => history.replace("/employees")} />{" "}
                    Employee Details
                </h3>
                <div className="menu-icon" onClick={()=>{
                    if(permissionStatusCode === 403){
                            showErrorToast(permissionErrorMessage)
                        }else{
                            setShowDropDown(!showDropDown)
                        }}}
                >
                <img src={showDropDown ? x :menu}  />
                        {/* <MenuIcon /> */}
                    </div>
                    {showDropDown && (
                        <div className="popover-menu">
                        <ul className="menu-list-a">

                            <li className="menu-item"
                                onClick={() => {
                                    if(employee.isActive){
                                        dispatch(setEditEmployeeData(employee))
                                        history.push("/employees/add/"+employee.staffId)
                                    }else{
                                        setShowDropDown(false)
                                        showErrorToast('Unblock the employee to perform this action')
                                    }
                                }}><img 
                                    src={edit} 
                                    style={{filter: "brightness(0) saturate(100%) invert(45%) sepia(22%) saturate(1556%) hue-rotate(49deg) brightness(94%) contrast(93%)"}}
                                    className='menuIcon'/>Edit</li>

                            <li className="menu-item" 
                                onClick={() => {
                                    setOpenStausModal(true)}
                                }><img
                                    src={employee?.isActive ? block : unBlockImg} 
                                    className='menuIcon'/>{employee?.isActive ? 'Block' : 'Unblock'}</li>
                            
                            <li className="menu-item" 
                                onClick={()=>{
                                    setDeleteOpenModal(!openDeleteModal)}
                                }><img 
                                    src={trash}
                                    style={{filter: "invert(21%) sepia(93%) saturate(7248%) hue-rotate(354deg) brightness(103%) contrast(101%)"}}
                                    className='menuIcon'/>Delete</li>

                        </ul>
                        </div>
                    )}
            </div>
            <Modal
                isOpen={openDeleteModal}
                message={credentials?.id == employee?.staffId ? 
                    `You're not allowed to perform this action` : 
                    <div>
                        <p>Are you sure you want to delete this user?</p>
                        <p>This action is irreversible, and all data will be permanently removed.</p>
                    </div>
                }
                onConfirm={handleDelete}
                onCancel={() => setDeleteOpenModal(false)}
                type={credentials?.id == employee?.staffId ? "alert" : "confirmation"}
                isLoading={modelApiLoading}
                logo={deleteIcon}
                propType={'deleteEmp'}
            />

            <Modal
                isOpen={openStausModal}
                message={credentials?.id == employee?.staffId ? `You're not allowed to perform this action`:employee?.isActive ?
                     <div>
                        <p>Are you sure you want to block this user?</p>
                        <p>This action will restrict their access until unblocked.</p>
                     </div> : 
                     'Are you sure you want to unblock this user?'}
                onConfirm={handleBtnClick}
                onCancel={() => {setOpenStausModal(false)}}
                type={credentials?.id == employee?.staffId ? "alert" : "confirmation"}
                isLoading={modelApiLoading}
                logo={employee.isActive ? blockIcon : activeIcon}
                propType={employee.isActive ? "Block"  :'Unblock'}
            />

            {/* {showDropDown && (
                <div className='hamburgerMenu' 
                        style={{
                            zIndex: '999999', 
                            marginTop:'20px',
                            position:'fixed'
                        }}
                >
                    
                </div>
            )} */}
            <div className='detailContainer' style={{marginTop:'100px'}}>
                <div>
                    <div className='title'><p className='tag'>Full Name</p><p className='value'>:</p><p className=''> {employee?.firstName} {employee?.lastName}</p></div>
                    <div className='title'>
                        <p className='tag'>Assigned Role</p>
                        <p className='value'>:</p>
                        <p className='' style={{display:'flex',gap:'10px'}}>  {employee?.assignedRole} {employee?.defaultFunctionalityAccessUpdated  && <img src={thunder} />}</p>
                    </div>
                    <div className='title'><p className='tag'>Assigned Outlet</p><p className='value'>:</p><p className=''>  {fomatLocation(employee?.locationName ?? '')}</p></div>
                    <div className='title'><p className='tag'>User ID</p><p className='value'>:</p><p className=''>  {employee?.userId}</p></div>                   
                    <div className='title'><p className='tag'>Date of Birth</p><p className='value'>:</p><p className=''>  {employee?.dateOfBirth ? formatDate(parseDate(employee.dateOfBirth) || new Date()) : '-'}</p></div>
                </div>
                <div>
                    <div className='title'><p className='tag'>Nick Name</p><p className='value'>:</p><p className=''>  {employee?.nickName?.trim() ? employee?.nickName?.trim() : '-'}</p></div>
                    <div className='title'><p className='tag'>Email</p><p className='value'>:</p><p className='' style={{width:'300px'}}>  {employee?.email?.trim() ? employee?.email?.trim() : '-'}</p></div>
                    <div className='title'><p className='tag'>Phone</p><p className='value'>:</p><p className=''>  {employee?.phone?.trim() ? formatPhoneNumber(employee?.phone?.trim(),countryCode) : '-'}</p></div>
                    <div className='title'><p className='tag'>Address</p><p className='value'>:</p><p style={{width:'250px'}}>  {employee?.address && !isEmptyOrSpaces(employee.address) ? employee.address : '-'}</p></div>
                    <div className='title'><p className='tag'>Education</p><p className='value'>:</p><p className=''>  {employee?.education ? employee?.education : '-'}</p></div>
                </div>
            </div>
            <div className='rolesContainer'>
            {!roleFunctionFetching ? (
                <div className='insideBox'>
                    <h4 style={{textAlign:'center'}}>Roles and Functions</h4>
                    {employee?.isActive && rolesAndFunctions?.length > 0 ? (
                        <div >
                              {(rolesAndFunctions as RolesAndFunctions[])?.map((module) => (
                              <div className="checkboxList" key={module?.module}>
                                <div className="checkBoxItem">
                                  <label>
                                    <p style={{fontWeight:600}}>{module?.module}</p>
                                  </label>
                                </div>
                                <div className="checkBoxItemfunction" >
                                {module?.funtions?.map((func,index) => (
                                  <div key={index}>
                                    <label className="checkboxLabel">                                
                                      <p className="funcName">{func}</p>
                                    </label>
                                  </div>
                                ))}
                                </div>
                              </div>
                            ))}      
                        </div>
                    ) :!employee?.isActive ?  (
                        <p style={{color: 'gray', textAlign:'center', marginTop:'10%'}}>{permissionErrorMessage}</p>
                    ):(
                        <p style={{color: 'gray', textAlign:'center', marginTop:'10%'}}>No data found</p>
                    )}
                </div>
            ) : (
                <p className='loadingText' style={{marginTop:'15%', textAlign:'center', fontWeight:'500'}}>Loading...</p>
            )}
            </div>
            <div style={{
                display:'flex',
                bottom:'0px',
            }}>
                <button 
                    className='backBtn' 
                    onClick={() => history.push("/employees")}>
                        Close
                </button>
            </div>
        </div>
        </div>
    )
}

export default EmployeeDetails
