import React, { useEffect, useState, useContext, useRef } from "react";
import "./SearchBox.scss";
import searchIcon from "../../../assets/svg/prodSearch.svg";
import NotFound from "../../../assets/svg/NotFound copy.svg";
import { Contextpagejs } from "../../../pages/productCatalog/contextpage";
import deleteIcon from "../../../assets/svg/imagepillcloseIcon.svg";

import { useSelector, useDispatch } from "react-redux";
import {
  getMenuRequest,
  searchForItem,
  storeMockDataFilteredRequest,
} from "redux/productCatalog/productCatalogActions";

const SearchBox = (props) => {
  const [searchTerm, setSearchTerm] = useState(""); // User input only
  const [displayTerm, setDisplayTerm] = useState(""); // User input + suggestion for display
  const [seletctedItem, setSeletctedItem] = useState(""); // User input + suggestion for display

  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [optionSelected, setOptionSelected] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [filteredOptionsDispatch, setFilteredOptionsDispatch] = useState([]);
  const [orgData, setOrgData] = useState([]);
  const [closeModal, setCloseModal] = useState(false);
  const menuDataLoading = useSelector(
    (state) => state.productCatalog?.menuDataLoading
  );
  const data = useSelector((state) => state.storeMockDataReducer.data);
  const dispatch = useDispatch();
  const { isExpanded } = useContext(Contextpagejs);
  const popupRef = useRef(null);
  const locationid = useSelector((state) => state.auth.selectedBranch?.id);

  useEffect(() => {
    const itemNames = menuData
      ?.flatMap((item) => item?.itemResponseList)
      .map((item) => item?.itemName);

    setOrgData(itemNames);
  }, [data]);
  useEffect(() => {
    if (searchTerm == "") {
      dispatch(searchForItem({}));
    }
  }, [searchTerm]);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setCloseModal(false);
    }
  };

  const selectedBranch = useSelector(
    (state) => state.auth.selectedBranch || null
  );

  // useEffect(() => {
  //   if (searchTerm === '') {
  //     dispatch(getMenuRequest(selectedBranch?.id));
  //   }
  // },[selectedBranch?.id])

  useEffect(() => {
    if (closeModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup on unmount
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [closeModal]);

  useEffect(() => {
    dispatch(storeMockDataFilteredRequest(filteredOptionsDispatch));
  }, [filteredOptionsDispatch]);

  const menuData = useSelector((state) => state.productCatalog?.menuData);

  useEffect(() => {
    if (menuData) {
      setSearchTerm("");
    }
  }, [menuData]);

  const [placeholder, setplaceholder] = useState("");

  const handleSearch = (e) => {
    let value = e.target.value;
    const regex = /^[a-zA-Z0-9\s]*$/;

    if (
      regex.test(value) &&
      !(value.length === 1 && value === " ") &&
      (!/^\d+$/.test(value) || value.length <= 4)
    ) {
      setSearchTerm(value);
      setDisplayTerm(value);
      filterOptions(value);
      setOptionSelected(false);
      setCloseModal(true);
    }
  };

  // // All - Categories and subCategory ItemResponse List
  // const allItems = menuData?.flatMap(allC => allC?.itemResponseList)
  // console.log({ allItems });

  // const allItemsPlusSubItemList = menuData?.flatMap(allItemsWithSub => allItemsWithSub?.subCategoryResponseList)
  // console.log({ allItemsPlusSubItemList })

  // const allNew = allItemsPlusSubItemList?.flatMap(allNew => allNew?.itemResponseList);
  // console.log({ allNew })

  // const allArray = [...allItems, ...allNew]
  // console.log({ allArray })

  // const everything = allArray?.map(everything => everything?.itemName)
  // console.log({ everything })

  // const everythingFM = allArray?.flatMap(everything => everything?.itemName)
  // console.log({ everythingFM })

  const filterOptions = (input) => {
    const allItems = menuData?.flatMap((allC) => allC?.itemResponseList);

    const allItemsPlusSubItemList = menuData?.flatMap(
      (allItemsWithSub) => allItemsWithSub?.subCategoryResponseList
    );
    const allNew = allItemsPlusSubItemList?.flatMap(
      (allNew) => allNew?.itemResponseList
    );

    const allArray = [...allItems, ...allNew];

    const everything = allArray?.map((everything) => ({
      itemName: everything?.itemName,
      itemCode: everything?.itemCode,
    }));

    // const filtered = everything?.filter(
    //   (item) =>
    //     item?.itemName?.toLowerCase()?.includes(input?.toLowerCase()) ||
    //     item?.itemCode?.toLowerCase()?.includes(input?.toLowerCase())
    // );
    const searchLower = input?.toLowerCase()
    const filtered = everything.filter((menu) => {
      return (
          menu.itemName.toLowerCase().includes(searchLower) ||
          menu.itemCode?.toLowerCase().includes(searchLower)
      )
      }).sort((a, b) => {
          const aLower = a.itemName.toLowerCase();
          const bLower = b.itemName.toLowerCase();

          const aStartsWith = aLower.startsWith(searchLower) ? 0 : 1;
          const bStartsWith = bLower.startsWith(searchLower) ? 0 : 1;

          if (aStartsWith !== bStartsWith) {
              return aStartsWith - bStartsWith;
          }

          return aLower.localeCompare(bLower);
      });

    const startsWithInput = filtered.find((item) =>
      item?.itemName.toLowerCase().startsWith(input.toLowerCase())
    );

    setplaceholder(startsWithInput || "");

    setFilteredOptions(filtered);
    const filteredItemNames = filtered?.map((item) => item?.itemName);

    setFilteredOptionsDispatch(filteredItemNames);

    if (filtered.length > 0 && input.length > 0) {
      const firstMatch = filtered[0];
      setHighlightedIndex(0);

      if (firstMatch?.itemName.toLowerCase().startsWith(input.toLowerCase())) {
        const suggestion = firstMatch?.itemName?.slice(input.length);
        setDisplayTerm(input + suggestion);
      } else {
        setDisplayTerm(input);
      }
      setHighlightedIndex(0);
    } else {
      setDisplayTerm(input);
    }
  };

  const handleOptionClick = (option) => {
    setSearchTerm(option);
    setDisplayTerm(option);
    setOptionSelected(true);
    setCloseModal(false);
    setSeletctedItem(option);
    let result = null;

    menuData?.forEach((category) => {
      category?.itemResponseList?.forEach((item) => {
        if (item?.itemName === option) {
          result = {
            categoryId: category.categoryId,
            categoryName: category.categoryName,
            itemResponseList: [item],
          };
        }
      });

      category?.subCategoryResponseList?.forEach((subCategory) => {
        subCategory?.itemResponseList?.forEach((item) => {
          if (item?.itemName === option) {
            result = {
              categoryId: category.categoryId,
              categoryName: category.categoryName,
              subCategoryResponseList: [
                {
                  subCategoryId: subCategory.subCategoryId,
                  subCategoryName: subCategory.subCategoryName,
                  itemResponseList: [item],
                },
              ],
            };
          }
        });
      });
    });

    dispatch(searchForItem(result));
    setFilteredOptions([]);
  };

  const highlightedRef = useRef(null);
  
  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) => {
        const newIndex = Math.min(filteredOptions.length - 1, prevIndex + 1);
        setSearchTerm(filteredOptions[newIndex]?.itemName);
        setDisplayTerm(filteredOptions[newIndex]);
        return newIndex;
      });
    }

    if (e.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) => {
        const newIndex = Math.max(0, prevIndex - 1);
        setSearchTerm(filteredOptions[newIndex]?.itemName);
        setDisplayTerm(filteredOptions[newIndex]?.itemName);
        return newIndex;
      });
    }

    if (e.key === "Enter") {
      if (highlightedIndex >= 0 && highlightedIndex < filteredOptions.length) {
        handleOptionClick(filteredOptions[highlightedIndex].itemName);
        setHighlightedIndex(-1);
      }
    }

    setTimeout(() => {
      highlightedRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }, 0);
    // if (e.key === 'Backspace') {
    //   if (optionSelected) {
    //     // If an option was selected, reset searchTerm and displayTerm
    //     setSearchTerm('');
    //     setDisplayTerm('');
    //     setOptionSelected(false); // Allow new input
    //     setFilteredOptions([]);   // Clear suggestions
    //   } else {
    //     setOptionSelected(false); // Allow for changing selection
    //   }
    // }
  };

  const openSearchModel = () => {
    setCloseModal(true);
    filterOptions(searchTerm);
  };

  const deletesearchText = () => {
    setCloseModal(false);
    if (seletctedItem === "") {
      setSeletctedItem("");
      setSearchTerm("");
    } else {
      setSearchTerm("");
      dispatch(getMenuRequest(locationid));
      setSeletctedItem("");
    }
  };
  return (
    <div className="MLSearch-Container">
      <div className="MLsearchbox">
        <input
          className={`${isExpanded ? 
            searchTerm ? "MLHeader-Search1-term" : "MLHeader-Search1" : 
            searchTerm ? "MLHeader-Search-term" : "MLHeader-Search"
          }`}
          value={`${searchTerm}`}
          placeholder={"Search by item name, item code"}
          onChange={handleSearch}
          onKeyDown={handleKeyDown}
          type="text"
          readOnly={menuDataLoading}
        />

        {!searchTerm ? (
          <img
            className={`${
              isExpanded ? "MLSerchIcon-Header1" : "MLSerchIcon-Header"
            }`}
            // className={"MLSerchIcon-Header1"}
            onClick={() => openSearchModel()}
            src={searchIcon}
            alt="Search Icon"
          />
        ) : (
          <img
            className={`${
              isExpanded ? "MLClearIcon-Header1" : "MLClearIcon-Header"
            }`}
            // className={"MLSerchIcon-Header1"}
            onClick={() => deletesearchText()}
            src={deleteIcon}
            alt="Clear Icon"
          />
        )}
      </div>
      <div>
        {searchTerm && closeModal && (
          <div
            ref={popupRef}
            className={`${
              isExpanded
                ? "MLSearch-Container-options1"
                : "MLSearch-Container-options-menu"
            } ${
              filteredOptions.length > 0 &&
              searchTerm !== "" &&
              "searched-item-box"
            }`}
          >
            <ul
              className={`${
                isExpanded ? "MLsearchBoxContainer1" : "MLsearchBoxContainer"
              } 
              
              `}
            >
              {filteredOptions.length > 0
                ? filteredOptions.map((option, index) => (
                    <li
                      key={index}
                      ref={index === highlightedIndex ? highlightedRef : null}
                      onClick={() => handleOptionClick(option.itemName)}
                      className={`${
                        index === highlightedIndex ? "MLhighlighted" : ""
                      }   ${
                        isExpanded
                          ? "list-of-item-name-expand"
                          : "list-of-item-name"
                      }
                      ${
                        highlightedIndex === index ? "no-hover" : "hover"
                      }                      
                      `}
                    >
                      <div
                        className={`
                         ${
                           isExpanded
                             ? "MLSearch-Container-options1-items"
                             : "MLSearch-Container-options-items"
                         }
  
                        ${
                          index === highlightedIndex
                            ? "list-of-item-name-padding"
                            : ""
                        }
                        `}
                      >
                        {option.itemName}{" "}
                        {option.itemCode !== "" &&
                        option.itemCode !== undefined &&
                        option.itemCode !== null ? (
                          <>
                            <span>-{option.itemCode}</span>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </li>
                  ))
                : !optionSelected && (
                    <div
                      className={
                        isExpanded
                          ? "MLSearch-Container-options1-none"
                          : "MLSearch-Container-options-none"
                      }
                    >
                      <img
                        className={
                          isExpanded
                            ? "MLNotFoundImageExpanded"
                            : "MLNotFoundImage"
                        }
                        src={NotFound}
                        alt="MLNo Results Found"
                      />
                      <h3
                        className={
                          isExpanded
                            ? "MLheading-none-expanded"
                            : "MLheading-none"
                        }
                      >
                        No Results Found
                      </h3>
                    </div>
                  )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBox;
