export const EmployeeD = {
  "Employee Summary": [{
    "S.No": 1,
    "Tips": 21.23,
    "Gratuity": 70,
  },
  {
    "S.No": 2,
    "Tips": 15.50,
    "Gratuity": 50
  },
  {
    "S.No": 3,
    "Tips": 30.75,
    "Gratuity": 90
  },
  {
    "S.No": 4,
    "Tips": 18.60,
    "Gratuity": 60
  },
  {
    "S.No": 5,
    "Tips": 25.40,
    "Gratuity": 80
  }
  ],
  "Employee Performance": [
    {
      "S.No": 1,
      "Employee": "Employee 2",
      "Working hrs": 8,
      "Orders Handled": 21,
      "Sales": 14384.68,
      "Tips": 7.23,
      "Gratuity": 15,
      "Orders cancelled": 6
    },
    {
      "S.No": 2,
      "Employee": "Employee 1",
      "Working hrs": 8,
      "Orders Handled": 11,
      "Sales": 14136.28,
      "Tips": 1.92,
      "Gratuity": 5,
      "Orders cancelled": 0
    },
    {
      "S.No": 3,
      "Employee": "Employee 4",
      "Working hrs": 8,
      "Orders Handled": 4,
      "Sales": 6359.21,
      "Tips": 2.78,
      "Gratuity": 8,
      "Orders cancelled": 0
    },
    {
      "S.No": 4,
      "Employee": "Employee 3",
      "Working hrs": 8,
      "Orders Handled": 15,
      "Sales": 5235.37,
      "Tips": 9.01,
      "Gratuity": 30,
      "Orders cancelled": 10
    },
    {
      "S.No": 5,
      "Employee": "Employee 5",
      "Working hrs": 8,
      "Orders Handled": 6,
      "Sales": 1263.23,
      "Tips": 0.29,
      "Gratuity": 12,
      "Orders cancelled": 20
    }
  ],
  "Employee Discount": [
    {
      "S.No": 1,
      "Employee": "Employee 2",
      "Total": 0
    },
    {
      "S.No": 2,
      "Employee": "Employee 1",
      "Total": 0
    },
    {
      "S.No": 3,
      "Employee": "Employee 4",
      "Total": 0
    },
    {
      "S.No": 4,
      "Employee": "Employee 3",
      "Total": 0
    },
    {
      "S.No": 5,
      "Employee": "Employee 5",
      "Total": 0
    }
  ],
  "Employee Void Activity": [
    {
      "S.No": 1,
      "Employee": "Employee 2",
      "Voided amount": 0,
      "Voided item": "",
      "Voided reason": ""
    },
    {
      "S.No": 2,
      "Employee": "Employee 1",
      "Voided amount": 0,
      "Voided item": "",
      "Voided reason": ""
    },
    {
      "S.No": 3,
      "Employee": "Employee 4",
      "Voided amount": 0,
      "Voided item": "",
      "Voided reason": ""
    },
    {
      "S.No": 4,
      "Employee": "Employee 3",
      "Voided amount": 0,
      "Voided item": "",
      "Voided reason": ""
    },
    {
      "S.No": 5,
      "Employee": "Employee 5",
      "Voided amount": 0,
      "Voided item": "",
      "Voided reason": ""
    }
  ]
};

// export const EmployeeD = [
//   {
//     "Employee Name": "Employee 2",
//     "Total Orders": 21,
//     Sales: 14384.68,
//     Tips: 7.23,
//     "Service Fee": 15,
//     "Total Discounts": 0,
//     "Total Items": 55,
//     "Total Canceled Items": 6,
//   },
//   {
//     "Employee Name": "Employee 1",
//     "Total Orders": 11,
//     Sales: 14136.28,
//     Tips: 1.92,
//     "Service Fee": 5,
//     "Total Discounts": 0,
//     "Total Items": 26,
//     "Total Canceled Items": 0,
//   },
//   {
//     "Employee Name": "Employee 4",
//     "Total Orders": 4,
//     Sales: 6359.21,
//     Tips: 2.78,
//     "Service Fee": 8,
//     "Total Discounts": 0,
//     "Total Items": 7,
//     "Total Canceled Items": 0,
//   },
//   {
//     "Employee Name": "Employee 3",
//     "Total Orders": 15,
//     Sales: 5235.37,
//     Tips: 9.01,
//     "Service Fee": 30,
//     "Total Discounts": 0,
//     "Total Items": 40,
//     "Total Canceled Items": 10,
//   },
//   {
//     "Employee Name": "Employee 5",
//     "Total Orders": 6,
//     Sales: 1263.23,
//     Tips: 0.29,
//     "Service Fee": 12,
//     "Total Discounts": 0,
//     "Total Items": 40,
//     "Total Canceled Items": 20,
//   },
// ];
