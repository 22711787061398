export const OFFER_LIST_REQUEST = "OFFER_LIST_REQUEST";
export const OFFER_LIST_SUCCESS = "OFFER_LIST_SUCCESS";
export const OFFER_LIST_FAILURE = "OFFER_LIST_FAILURE";

export const DROPDOWN_DATA_REQUEST = "DROPDOWN_DATA_REQUEST";
export const DROPDOWN_DATA_SUCCESS = "DROPDOWN_DATA_SUCCESS";
export const DROPDOWN_DATA_FAILURE = "DROPDOWN_DATA_FAILURE";

export const CREATE_OFFER_REQUEST = "CREATE_OFFER_REQUEST";
export const CREATE_OFFER_SUCCESS = "CREATE_OFFER_SUCCESS";
export const CREATE_OFFER_FAILURE = "CREATE_OFFER_FAILURE";
export const CREATE_OFFER_CLEAR = "CREATE_OFFER_CLEAR";
export const CLEAR_OFFER_SUCCESS = "CLEAR_OFFER_SUCCESS";


export const EDIT_OFFER_REQUEST = "EDIT_OFFER_REQUEST";
export const EDIT_OFFER_SUCCESS = "EDIT_OFFER_SUCCESS";
export const EDIT_OFFER_FAILURE = "EDIT_OFFER_FAILURE";
export const UPDATE_OFFER_CLEAR = "UPDATE_OFFER_CLEAR";
// Disable Offer
export const DISABLE_OFFER_REQUEST = " DISABLE_OFFER_REQUEST";
export const DISABLE_OFFER_SUCCESS = "DISABLE_OFFER_SUCCESS";
export const DISABLE_OFFER_FAILED = "DISABLE_OFFER_FAILED";
export const RESET_DISABLE_DATA = "RESET_DISABLE_DATA";


// Delete Offer
export const DELETE_OFFER_REQUEST = " DELETE_OFFER_REQUEST";
export const DELETE_OFFER_SUCCESS = "DELETE_OFFER_SUCCESS";
export const DELETE_OFFER_FAILED = "DELETE_OFFER_FAILED";
export const RESET_DELETE_DATA = "RESET_DELETE_DATA";

export const SET_OFFER_STATUS = "SET_OFFER_STATUS";


export const  OFFER_DATA_REQUEST="OFFER_DATA_REQUEST";
export const  OFFER_DATA_SUCCESS="OFFER_DATA_SUCCESS";
export const  OFFER_DATA_FAILED="OFFER_DATA_FAILED";

export const CATEGORY_FETCHDROPDOWN_REQUEST = "CATEGORY_FETCHDROPDOWN_REQUEST";
export const CATEGORY_FETCHDROPDOWN_SUCCESS = "CATEGORY_FETCHDROPDOWN_SUCCESS";
export const CATEGORY_FETCHDROPDOWN_FAILURE = "CATEGORY_FETCHDROPDOWN_FAILURE";

export const SUB_CATEGORY_FETCHDROPDOWN_REQUEST = "SUB_CATEGORY_FETCHDROPDOWN_REQUES";
export const SUB_CATEGORY_FETCHDROPDOWN_SUCCESS = "SUB_CATEGORY_FETCHDROPDOWN_SUCCESS";
export const SUB_CATEGORY_FETCHDROPDOWN_FAILURE = "SUB_CATEGORY_FETCHDROPDOWN_FAILURE";


export const CREATE_SPECIAL_OFFER_REQUEST = "CREATE_SPECIAL_OFFER_REQUEST";
export const CREATE_SPECIAL_OFFER_SUCCESS = "CREATE_SPECIAL_OFFER_SUCCESS";
export const CREATE_SPECIAL_OFFER_OVERLAP = "CREATE_SPECIAL_OFFER_OVERLAP";

export const CREATE_SPECIAL_OFFER_FAILURE = "CREATE_SPECIAL_OFFER_FAILURE";



export const UPDATE_SPECIAL_OFFER_REQUEST = "UPDATE_SPECIAL_OFFER_REQUEST";
export const UPDATE_SPECIAL_OFFER_SUCCESS = "UPDATE_SPECIAL_OFFER_SUCCESS";
export const UPDATE_SPECIAL_OFFER_FAILURE = "UPDATE_SPECIAL_OFFER_FAILURE";



export const  SP_OFFER_LIST_SENDING_REQUEST="SP_OFFER_LIST_SENDING_REQUEST";
export const  SP_OFFER_LIST_SENDING_SUCCESS="SP_OFFER_LIST_SENDING_SUCCESS";
export const  SP_OFFER_LIST_SENDING_FAILED="SP_OFFER_LIST_SENDING_FAILED";

export const  SP_OFFER_LIST_VIEW_REQUEST="SP_OFFER_LIST_VIEW_REQUEST";
export const  SP_OFFER_LIST_VIEW_SUCCESS="SP_OFFER_LIST_VIEW_SUCCESS";
export const  SP_OFFER_LIST_VIEW_FAILED="SP_OFFER_LIST_VIEW_FAILED";


export const  SP_OFFER_LIST_DELETE_REQUEST="SP_OFFER_LIST_DELETE_REQUEST";
export const  SP_OFFER_LIST_DELETE_SUCCESS="SP_OFFER_LIST_DELETE_SUCCESS";
export const  SP_OFFER_LIST_DELETE_FAILED="SP_OFFER_LIST_DELETE_FAILED";



export const  SP_OFFER_LIST_DISABLE_REQUEST="SP_OFFER_LIST_DISABLE_REQUEST";
export const  SP_OFFER_LIST_DISABLE_SUCCESS="SP_OFFER_LIST_DISABLE_SUCCESS";
export const  SP_OFFER_LIST_DISABLE_FAILED="SP_OFFER_LIST_DISABLE_FAILED";

export const  SP_OFFER_LIST_EDIT_REQUEST="SP_OFFER_LIST_EDIT_REQUEST";
// export const  SP_OFFER_LIST_EDIT_SUCCESS="SP_OFFER_LIST_DISABLE_SUCCESS";
// export const  SP_OFFER_LIST_EDIT_FAILED="SP_OFFER_LIST_DISABLE_FAILED";
export const GET_OFFER_ITEMS_REQUEST = "GET_OFFER_ITEMS_REQUEST";
export const GET_OFFER_ITEMS_SUCCESS = "GET_OFFER_ITEMS_SUCCESS";
export const GET_OFFER_ITEMS_FAILURE = "GET_OFFER_ITEMS_SUCCESS";

export const REMOVE_EDITDATA_WHEN_ADD = "REMOVE_EDITDATA_WHEN_ADD";



