//POST

export const SAVE_WELCOME_BG_IMAGE_REQUEST = "SAVE_WELCOME_BG_IMAGE_REQUEST"
export const SAVE_WELCOME_BG_IMAGE_SUCCESS = "SAVE_WELCOME_BG_IMAGE_SUCCESS"
export const SAVE_WELCOME_BG_IMAGE_FAILURE = "SAVE_WELCOME_BG_IMAGE_FAILURE"

export const SAVE_RESTAURANT_LOGO_REQUEST = "SAVE_RESTAURANT_LOGO_REQUEST"
export const SAVE_RESTAURANT_LOGO_SUCCESS = "SAVE_RESTAURANT_LOGO_SUCCESS"
export const SAVE_RESTAURANT_LOGO_FAILURE = "SAVE_RESTAURANT_LOGO_FAILURE"

export const SAVE_RESTAURANT_VIDEO_REQUEST = "SAVE_RESTAURANT_VIDEO_REQUEST"
export const SAVE_RESTAURANT_VIDEO_SUCCESS = "SAVE_RESTAURANT_VIDEO_SUCCESS"
export const SAVE_RESTAURANT_VIDEO_FAILURE = "SAVE_RESTAURANT_VIDEO_FAILURE"

export const SAVE_RESTAURANT_FOOD_IMAGE_REQUEST = "SAVE_RESTAURANT_FOOD_IMAGE_REQUEST"
export const SAVE_RESTAURANT_FOOD_IMAGE_SUCCESS = "SAVE_RESTAURANT_FOOD_IMAGE_SUCCESS"
export const SAVE_RESTAURANT_FOOD_IMAGE_FAILURE = "SAVE_RESTAURANT_FOOD_IMAGE_FAILURE"

export const RESTAURANT_INFRASTRUCTURE_IMAGE_REQUEST = "RESTAURANT_INFRASTRUCTURE_IMAGE_REQUEST"
export const RESTAURANT_INFRASTRUCTURE_IMAGE_SUCCESS = "RESTAURANT_INFRASTRUCTURE_IMAGE_SUCCESS"
export const RESTAURANT_INFRASTRUCTURE_IMAGE_FAILURE = "RESTAURANT_INFRASTRUCTURE_IMAGE_FAILURE"

export const HAPPY_CUSTOMER_IMAGE_REQUEST = "HAPPY_CUSTOMER_IMAGE_REQUEST"
export const HAPPY_CUSTOMER_IMAGE_SUCCESS = "HAPPY_CUSTOMER_IMAGE_SUCCESS"
export const HAPPY_CUSTOMER_IMAGE_FAILURE = "HAPPY_CUSTOMER_IMAGE_FAILURE"

export const LOYALTY_IMAGE_REQUEST = "LOYALTY_IMAGE_REQUEST"
export const LOYALTY_IMAGE_SUCCESS = "LOYALTY_IMAGE_SUCCESS"
export const LOYALTY_IMAGE_FAILURE = "LOYALTY_IMAGE_FAILURE"

export const SAVE_EXPLORE_VIDEO_REQUEST = "SAVE_EXPLORE_VIDEO_REQUEST"
export const SAVE_EXPLORE_VIDEO_SUCCESS = "SAVE_EXPLORE_VIDEO_SUCCESS"
export const SAVE_EXPLORE_VIDEO_FAILURE = "SAVE_EXPLORE_VIDEO_FAILURE"

export const SAVE_EXPLORE_FOOTER_IMAGE_REQUEST = "SAVE_EXPLORE_FOOTER_IMAGE_REQUEST"
export const SAVE_EXPLORE_FOOTER_IMAGE_SUCCESS = "SAVE_EXPLORE_FOOTER_IMAGE_SUCCESS"
export const SAVE_EXPLORE_FOOTER_IMAGE_FAILURE = "SAVE_EXPLORE_FOOTER_IMAGE_FAILURE"

export const SAVE_FULL_MENU_VIDEO_REQUEST = "SAVE_FULL_MENU_VIDEO_REQUEST"
export const SAVE_FULL_MENU_VIDEO_SUCCESS = "SAVE_FULL_MENU_VIDEO_SUCCESS"
export const SAVE_FULL_MENU_VIDEO_FAILURE = "SAVE_FULL_MENU_VIDEO_FAILURE"

export const SAVE_FEEDBACK_IMAGE_REQUEST = "SAVE_FEEDBACK_IMAGE_REQUEST"
export const SAVE_FEEDBACK_IMAGE_SUCCESS = "SAVE_FEEDBACK_IMAGE_SUCCESS"
export const SAVE_FEEDBACK_IMAGE_FAILURE = "SAVE_FEEDBACK_IMAGE_FAILURE"

//GET

export const GET_WELCOME_BG_IMAGE_REQUEST = "GET_WELCOME_BG_IMAGE_REQUEST"
export const GET_WELCOME_BG_IMAGE_SUCCESS = "GET_WELCOME_BG_IMAGE_SUCCESS"
export const GET_WELCOME_BG_IMAGE_FAILURE = "GET_WELCOME_BG_IMAGE_FAILURE"

export const GET_RESTAURANT_LOGO_REQUEST = "GET_RESTAURANT_LOGO_REQUEST"
export const GET_RESTAURANT_LOGO_SUCCESS = "GET_RESTAURANT_LOGO_SUCCESS"
export const GET_RESTAURANT_LOGO_FAILURE = "GET_RESTAURANT_LOGO_FAILURE"

export const GET_RESTAURANT_VIDEO_REQUEST = "GET_RESTAURANT_VIDEO_REQUEST"
export const GET_RESTAURANT_VIDEO_SUCCESS = "GET_RESTAURANT_VIDEO_SUCCESS"
export const GET_RESTAURANT_VIDEO_FAILURE = "GET_RESTAURANT_VIDEO_FAILURE"

export const GET_RESTAURANT_FOOD_IMAGE_REQUEST = "GET_RESTAURANT_FOOD_IMAGE_REQUEST"
export const GET_RESTAURANT_FOOD_IMAGE_SUCCESS = "GET_RESTAURANT_FOOD_IMAGE_SUCCESS"
export const GET_RESTAURANT_FOOD_IMAGE_FAILURE = "GET_RESTAURANT_FOOD_IMAGE_FAILURE"

export const GET_RESTAURANT_INFRASTRUCTURE_IMAGE_REQUEST = "GET_RESTAURANT_INFRASTRUCTURE_IMAGE_REQUEST"
export const GET_RESTAURANT_INFRASTRUCTURE_IMAGE_SUCCESS = "GET_RESTAURANT_INFRASTRUCTURE_IMAGE_SUCCESS"
export const GET_RESTAURANT_INFRASTRUCTURE_IMAGE_FAILURE = "GET_RESTAURANT_INFRASTRUCTURE_IMAGE_FAILURE"

export const GET_HAPPY_CUSTOMER_IMAGE_REQUEST = "GET_HAPPY_CUSTOMER_IMAGE_REQUEST"
export const GET_HAPPY_CUSTOMER_IMAGE_SUCCESS = "GET_HAPPY_CUSTOMER_IMAGE_SUCCESS"
export const GET_HAPPY_CUSTOMER_IMAGE_FAILURE = "GET_HAPPY_CUSTOMER_IMAGE_FAILURE"

export const GET_LOYALTY_IMAGE_REQUEST = "GET_LOYALTY_IMAGE_REQUEST"
export const GET_LOYALTY_IMAGE_SUCCESS = "GET_LOYALTY_IMAGE_SUCCESS"
export const GET_LOYALTY_IMAGE_FAILURE = "GET_LOYALTY_IMAGE_FAILURE"

export const GET_EXPLORE_VIDEO_REQUEST = "GET_EXPLORE_VIDEO_REQUEST"
export const GET_EXPLORE_VIDEO_SUCCESS = "GET_EXPLORE_VIDEO_SUCCESS"
export const GET_EXPLORE_VIDEO_FAILURE = "GET_EXPLORE_VIDEO_FAILURE"

export const GET_EXPLORE_FOOTER_IMAGE_REQUEST = "GET_EXPLORE_FOOTER_IMAGE_REQUEST"
export const GET_EXPLORE_FOOTER_IMAGE_SUCCESS = "GET_EXPLORE_FOOTER_IMAGE_SUCCESS"
export const GET_EXPLORE_FOOTER_IMAGE_FAILURE = "GET_EXPLORE_FOOTER_IMAGE_FAILURE"

export const GET_FULL_MENU_VIDEO_REQUEST = "GET_FULL_MENU_VIDEO_REQUEST"
export const GET_FULL_MENU_VIDEO_SUCCESS = "GET_FULL_MENU_VIDEO_SUCCESS"
export const GET_FULL_MENU_VIDEO_FAILURE = "GET_FULL_MENU_VIDEO_FAILURE"

export const GET_FEEDBACK_IMAGE_REQUEST = "GET_FEEDBACK_IMAGE_REQUEST"
export const GET_FEEDBACK_IMAGE_SUCCESS = "GET_FEEDBACK_IMAGE_SUCCESS"
export const GET_FEEDBACK_IMAGE_FAILURE = "GET_FEEDBACK_IMAGE_FAILURE"
